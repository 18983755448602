<template>
  <BaseModal
    name="modal-arquivos"
    size="md"
    :title="$t('seller.products.entrega_arquivos.text_341')"
    @shown="fetchRegras"
  >
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <section class="container-arquivos" v-else>
      <div class="lista-entrega lista-arquivos mt-4">
        <div>
          <h4 class="title-inter">• Arquivos</h4>
          <b-form data-vv-scope="">
            <b-col cols="12">
              <b-form-group label="" label-for="contract" data-anima="top">
                <b-form-file
                  id="arquivo"
                  name="arquivo"
                  class="arquivo-input"
                  plain
                  accept=".pdf, .zip, .doc, .xls, .xlsx, .rar"
                  multiple
                  @change="changeFiles"
                  v-validate="'required'"
                ></b-form-file>
                <!-- accept=".pdf,.zip,.rar,.7zip" -->
                <div class="drag-drop">
                  <img src="@/assets/img/icons/files.svg" />
                  <p>{{ $t('seller.products.entrega_arquivos.text_342') }}</p>
                  <BaseButton variant="info2" class="escolher-file"
                    >{{ $t('seller.products.entrega_arquivos.text_343') }}</BaseButton
                  >
                </div>

                <ul>
                  <li
                    v-for="(file, index) in files"
                    :key="file.name"
                    class="arquivo-item"
                    data-anima="top"
                  >
                    {{ file.name }}
                    <div class="item-lixeira">
                      <img
                        src="@/assets/img/icons/lixeira.svg"
                        class="icon-lixeira"
                        @click="removeFile(index)"
                      />
                    </div>
                  </li>
                </ul>

                <b-form-invalid-feedback :state="!errors.has('arquivo')">
                  {{ $t('seller.products.entrega_arquivos.text_344') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-form>
        </div>
      </div>
    </section>

    <template v-slot:footer="{ cancel }">
      <BaseButton
        :disabled="loading"
        variant="secondary_outline"
        class="mr-4"
        @click="cancel"
      >
        {{ $t('seller.products.entrega_arquivos.text_1894') }}
      </BaseButton>
      <div id="btn-add">
        <BaseButton
          variant="secondary"
          :disabled="loading || !files.length"
          @click="onSubmit"
        >
          {{ $t('seller.products.entrega_arquivos.text_1895') }}
        </BaseButton>
      </div>
      <b-tooltip
        v-if="!files.length"
        target="btn-add"
        :title="$t('seller.products.entrega_arquivos.text_345')"
      />
    </template>
  </BaseModal>
</template>

<script>
import IntegrationService from "@/services/resources/IntegrationService";
const serviceIntegration = IntegrationService.build();

import FileService from "@/services/resources/FileService";
const serviceFiles = FileService.build();

import axios from "axios";
import Cookies from "js-cookie";
export default {
  data() {
    return {
      loading: false,
      submit: false,
      files: [],
      regras: [],
    };
  },
  props: {
    id_product: {
      type: Number,
    },
  },

  methods: {
    jsonToFormData(data) {
      const formData = new FormData();

      this.buildFormData(formData, data);
      return formData;
    },
    buildFormData(formData, data, parentKey) {
      if (
        data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File)
      ) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          );
        });
      } else {
        const value = data == null ? "" : data;

        formData.append(parentKey, value);
      }
    },
    fetchRegras() {
      let data = {
        product_id: this.id_product,
        type: "file",
        url: "list",
      };

      serviceIntegration
        .search(data)
        .then((response) => {
          this.regras = response;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async onSubmit() {
      this.loading = true;

      let all_promises = [];
      all_promises["regras"] = await this.addRegra();
      all_promises["files"] = await this.addFiles();

      Promise.all(all_promises)
        .then((resp) => {
          // fechando modal
          this.$bvModal.hide("modal-arquivos");
          this.$bvToast.toast(this.$t('seller.products.entrega_arquivos.text_346'), {
            title: this.$t('seller.products.entrega_arquivos.text_1896'),
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addFiles() {
      return new Promise((resolve) => {
        this.files.forEach((f) => {
          this.submitFile(f)
            .then((resp) => {
              if (resp) {
                //emitindo evento para o componente pai (opcoesEntrega)
                this.$emit("close", resp);
              }
            })
            .then(() => {
              resolve(true);
            });
        });
      });
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          reject(error);
        };
      });
    },
    submitFile(file) {
      this.loading = true;
      return new Promise((resolve) => {
        let data = {
          product_id: this.id_product,
          attachment: file,
          _method: "PUT",
        };

        data = this.jsonToFormData(data);

        serviceFiles
          .create(data, this.id_product)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
            resolve(false);
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    addRegra() {
      return new Promise((resolve) => {
        if (!this.regras.length) {
          let data = {
            type: "file",
            product_id: this.id_product,
          };
          resolve(true);
          serviceIntegration
            .create(data)
            .then(() => {
              resolve(true);
            })
            .catch((err) => {
              console.log(err);
              resolve(false);
            });
        } else resolve(true);
      });
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },
    changeFiles(e) {
      this.files = [...this.files, ...e.target.files];
      this.files = this.files.reverse();
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
.title-inter {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #2a63ab;
}
input.arquivo-input {
  height: 35vh !important;
  z-index: 9999;
  opacity: 0;
  cursor: pointer;
}

.drag-drop {
  width: 100%;
  border-radius: 5px;
  border: 2px dashed rgba(33, 51, 210, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px;
  position: absolute;
  top: 0;
  z-index: -1;
}
.drag-drop .escolher-file {
  background: rgba(33, 51, 210, 0.2);
  color: #2a63ab;
  border: none;
  padding: 0 25px !important;
  height: 40px;
  font-size: 13px;
  margin-top: 20px;
}
.lista-entrega ul li .icon-lixeira {
  width: 14px;
  filter: invert(50%);
}
.icon-lixeira:hover {
  filter: invert(0) !important;
}
.arquivo-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  background: #f7f7f7;
  border-radius: 5px;
  font-size: 15px;
}
.arquivo-item + .arquivo-item {
  margin-top: 10px;
}
.arquivo-item .item-lixeira {
  width: 20px;
}
.arquivo-item img {
  cursor: pointer;
}
</style>
