<template>
  <BaseModal
    name="modal-integracoes_gdigital"
    size="lg"
    :title="$t('seller.products.gdigital.text_347')"
    @shown="openModal"
    @hidden="clearFields"
  >
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <section class="container-arquivos" v-else>
      <div class="lista-entrega lista-arquivos mt-4">
        <!-- gDigital -->
        <div>
          <div class="d-flex justify-content-between">
            <h4 class="title-inter">• G Digital</h4>
          </div>
          <b-form data-vv-scope="gdigital">
            <div
              data-anima="top"
              class="codigo-verifica"
              v-if="!integration || !token"
            >
              <div v-if="novo_email == false" data-anima="top">
                <p>
                  {{ $t('seller.products.gdigital.text_348') }}
                  {{ email || $store.state.user.user.email }}
                  {{ $t('seller.products.gdigital.text_349') }}
                </p>
                <b-form-group
                  :label="$t('seller.products.gdigital.text_1897')"
                  label-for="code_verification"
                >
                  <b-form-input
                    v-model="code"
                    name="code_verification"
                    id="code_verification"
                    :placeholder="$t('seller.products.gdigital.text_351')"
                    autocomplete="code_verification"
                    v-validate="'required'"
                  />
                  <b-form-invalid-feedback
                    :state="!errors.has('gdigital.code_verification')"
                  >
                    {{ $t('seller.products.gdigital.text_350') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
              <div v-if="novo_email" data-anima="top">
                <p v-if="g_email">
                  {{ $t('seller.products.gdigital.text_352') }}
                </p>
                <p v-else>
                  {{ $t('seller.products.gdigital.text_353') }}
                  <br />
                  {{ $t('seller.products.gdigital.text_354') }}
                  {{ $store.state.user.user.email }},
                  {{ $t('seller.products.gdigital.text_355') }} <br /><br />
                  {{ $t('seller.products.gdigital.text_356') }}
                </p>
                <b-form-group
                  :label="$t('seller.products.gdigital.text_357')"
                  label-for="novo_email"
                >
                  <b-form-input
                    v-model="email"
                    name="novo_email"
                    id="novo_email"
                    :placeholder="$t('seller.products.gdigital.text_1898')"
                    autocomplete="novo_email"
                    v-validate="'required|email'"
                  />
                  <b-form-invalid-feedback
                    :state="!errors.has('gdigital.novo_email')"
                  >
                    {{ $t('seller.products.gdigital.text_358') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <a
                  href="#"
                  class="sem-codigo"
                  @click.prevent="novo_email = !novo_email"
                  >{{
                    novo_email
                      ? $t('seller.products.gdigital.text_359')
                      : $t('seller.products.gdigital.text_360')
                  }}</a
                >
                <a
                  href="#"
                  class="sem-codigo"
                  v-if="!novo_email"
                  @click.prevent="reenviarCodigoGdigital"
                  >{{ $t('seller.products.gdigital.text_361') }}</a
                >
                <a href="#" class="sem-codigo" v-else></a>
              </div>
            </div>

            <b-row v-if="integration && token" class="d-flex">
              <b-col cols="6" data-anima="top" v-if="!loading">
                <b-form-group
                  :label="$t('seller.products.gdigital.text_362')"
                  label-for="blogs"
                >
                  <b-form-select
                    v-model="blog"
                    :options="blogs_gdigital"
                    name="blogs"
                    id="blogs"
                    :placeholder="$t('seller.products.gdigital.text_1899')"
                    @change="changeBlogs"
                  >
                  </b-form-select>
                  <b-form-invalid-feedback
                    :state="!errors.has('gdigital.blogs')"
                  >
                    {{ $t('seller.products.gdigital.text_363') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col cols="12" v-if="select_blog && !loading" data-anima="top">
                <h6 class="titulo-acao">{{ $t('seller.products.gdigital.text_364') }}</h6>
                <div class="grid-events">
                  <b-form-group :label="$t('seller.products.gdigital.text_1900')" label-for="check">
                    <b-form-checkbox
                      size="md"
                      switch
                      class="check-box mb-1"
                      name="check"
                      v-for="op in options_sales"
                      :key="op.value"
                      @change="triggerChanged(op.value)"
                    >
                      <p class="info-checkbox">{{ op.text }}</p>
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group label="Assinatura" label-for="check">
                    <b-form-checkbox
                      size="md"
                      switch
                      class="check-box mb-2"
                      name="check"
                      v-for="op in options_sub"
                      :key="op.value"
                      @change="triggerChanged(op.value)"
                    >
                      <p class="info-checkbox">{{ op.text }}</p>
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group label="Checkout" label-for="check">
                    <b-form-checkbox
                      size="md"
                      switch
                      class="check-box mb-2"
                      name="check"
                      v-for="op in options_checkout"
                      :key="op.value"
                      @change="triggerChanged(op.value)"
                    >
                      <p class="info-checkbox">{{ op.text }}</p>
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </b-col>
              <b-col cols="12" v-if="select_blog && !loading" data-anima="top">
                {{ forms.length }}
                <b-form-group
                  :label="$t('seller.products.gdigital.text_365')"
                  label-for="forms_gdigital"
                >
                  <multiselect
                    v-model="form_id"
                    :options="forms"
                    :value="form_id"
                    :multiple="false"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :placeholder="$t('seller.products.gdigital.text_365')"
                    label="text"
                    track-by="text"
                    selectLabel=""
                    deselectLabel=""
                    selectedLabel="✔️"
                    id="multi_select_forms_gdigital"
                    name="forms_gdigital"
                    v-validate="'required'"
                  >
                  </multiselect>
                  <b-form-invalid-feedback
                    :state="!errors.has('gdigital.forms_gdigital')"
                  >
                    {{ $t('seller.products.gdigital.text_366') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <div class="d-flex justify-content-center" v-if="loading">
                <b-spinner label="Loading..."></b-spinner>
              </div>
            </b-row>
          </b-form>
        </div>
      </div>
    </section>

    <template v-slot:footer="{ cancel }" data-anima="top">
      <BaseButton
        :disabled="loading"
        variant="secondary_outline"
        class="mr-4"
        @click="cancel"
      >
        {{ $t('seller.products.gdigital.text_1901') }}</BaseButton
      >

      <BaseButton
        variant="link-info"
        :disabled="loading"
        @click="initCreateCallBack"
        v-if="!loading && integration && token"
      >
        {{ $t('seller.products.gdigital.text_1902') }}
      </BaseButton>

      <BaseButton
        variant="link-info"
        :disabled="loading"
        @click="validaCodigo"
        v-if="!loading && !integration && !novo_email"
        >{{ $t('seller.products.gdigital.text_367') }}</BaseButton
      >
      <BaseButton
        variant="link-info"
        :disabled="loading"
        @click="enviar_codigo"
        v-if="!loading && !integration && novo_email"
        >{{ $t('seller.products.gdigital.text_1903') }}</BaseButton
      >
    </template>
  </BaseModal>
</template>

<script>
import { api_gdigital } from "@/services/Gdigital.js";
import { convertTrigger } from "@/helpers.js";
import Multiselect from "vue-multiselect";
import CallbackService from "@/services/resources/CallbackService";
const serviceCallback = CallbackService.build();

export default {
  data() {
    return {
      loading: false,
      submit: false,
      integration: false,
      token: "",
      novo_email: false,
      email: "",
      blog: "",
      code: "",
      trigger: [],
      form_id: -1,
      select_blog: false,
      options_sales: [
        {
          value: "salePaid",
          text: "Venda Paga",
        },
        {
          value: "saleRefused",
          text: "Venda Recusada",
        },
        {
          value: "saleRefunded",
          text: "Venda Reembolsada",
        },
        {
          value: "saleChargedback",
          text: "Chargeback realizado",
        },
        {
          value: "saleUnpaid",
          text: "Venda não paga",
        },
        {
          value: "saleWaitingPayment",
          text: "Venda aguardando pagamento",
        },
      ],
      options_sub: [
        {
          value: "contractUnpaid",
          text: "Assinatura não paga",
        },
        {
          value: "contractPaid",
          text: "Assinatura paga",
        },
        {
          value: "contractTrialing",
          text: "Assinatura em Trial",
        },
        {
          value: "contractPendingPayment",
          text: "Assinatura aguardando pagamento",
        },
        {
          value: "contractEnded",
          text: "Assinatura Finalizada",
        },
        {
          value: "contractCanceled",
          text: "Assinatura Cancelada",
        },
      ],
      options_checkout: [
        {
          value: "checkoutAbandoned",
          text: "Checkout Abandonado",
        },
      ],
      forms: [],
      blogs_gdigital: [],
      g_email: true,
    };
  },
  components: {
    Multiselect,
  },
  props: {
    product: {
      type: Object,
      default() {
        return {};
      },
    },
    gdigital: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    // checkbox dos eventos
    triggerChanged(v) {
      if (this.trigger.includes(v)) {
        this.trigger.splice(this.trigger.indexOf(v), 1);
      } else {
        this.trigger.push(v);
      }
    },

    // limpa os campos / reseta
    clearFields() {
      this.loading = false;
      this.submit = false;
      this.integration = false;
      this.token = "";
      this.novo_email = false;
      this.email = "";
      this.blog = "";
      this.code = "";
      this.trigger = [];
      this.form_id = -1;
      this.select_blog = false;
      this.options_sales = [
        {
          value: "salePaid",
          text: "Venda Paga",
        },
        {
          value: "saleRefused",
          text: "Venda Recusada",
        },
        {
          value: "saleRefunded",
          text: "Venda Reembolsada",
        },
        {
          value: "saleChargedback",
          text: "Chargeback realizado",
        },
        {
          value: "saleUnpaid",
          text: "Venda não paga",
        },
        {
          value: "saleWaitingPayment",
          text: "Venda aguardando pagamento",
        },
      ];
      this.options_sub = [
        {
          value: "contractUnpaid",
          text: "Assinatura não paga",
        },
        {
          value: "contractPaid",
          text: "Assinatura paga",
        },
        {
          value: "contractTrialing",
          text: "Assinatura em Trial",
        },
        {
          value: "contractPendingPayment",
          text: "Assinatura aguardando pagamento",
        },
        {
          value: "contractEnded",
          text: "Assinatura Finalizada",
        },
      ];
      this.forms = [];
      this.blogs_gdigital = [];
    },

    // iniciando a criação das callbacks
    initCreateCallBack() {
      this.createCallBack()
        .then((r) => {
          if (r) {
            try {
              setTimeout(() => {
                this.$bvModal.hide("modal-integracoes_gdigital");
                this.$emit("close");
                this.clearFields();
              }, 1000);
            } catch {
              setTimeout(() => {
                this.$bvModal.hide("modal-integracoes_gdigital");
                this.$emit("close");
                this.clearFields();
              }, 1000);
            }
          }
        })
        .then(() => {
          setTimeout(() => {
            this.$bvModal.hide("modal-integracoes_gdigital");
            this.$emit("close");
            this.clearFields();
          }, 1000);
        });
    },

    // adicionar uma nova callback
    createCallBack() {
      return new Promise((resolve) => {
        this.$validator.validateAll("gdigital").then((result) => {
          if (result) {
            this.submit = true;
            this.loading = true;
            const newForm = this.form_id.value;
            if (newForm) {
              let data = {
                product_id: this.product.id,
                id_form: newForm,
                type: "gdigital",
                callback: "cadastrarGdigital",
              };

              if (this.trigger.length) {
                this.trigger.forEach((event) => {
                  data.trigger = "";
                  setTimeout(async () => {
                    data.trigger = event;
                    await this.postCallback(data, event);
                    resolve(true);
                  }, 500);
                });
              } else {
                this.loading = false;
                this.$bvToast.toast(this.$t('seller.products.gdigital.text_1904'), {
                  title: "G Digital",
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
                resolve(false);
              }
            } else {
              this.loading = false;
              this.$bvToast.toast(this.$t('seller.products.gdigital.text_368'), {
                title: "G Digital",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
              resolve(false);
            }
          } else {
            resolve(false);
          }
        });
      });
    },

    async postCallback(data, event) {
      serviceCallback
        .create(data)
        .then(() => {
          this.$bvToast.toast(
            `[${convertTrigger(event)}] this.$t('seller.products.gdigital.text_1905')`,
            {
              title: "G Digital",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.submit = false;
          this.loading = false;
        });
    },
    // puxa todos os formularios da g digital
    getFormsGdigital() {
      this.loading = true;
      this.forms = [];
      api_gdigital
        .get(`/forms`)
        .then((r) => {
          // this.forms = r.data;
          [...r.data].forEach((item) => {
            this.forms.push({
              value: item.ID,
              text: item.post_title,
            });
          });
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },

    // evento change par atualizar o auth (forms)
    changeBlogs() {
      this.select_blog = true;
      const array = this.blog.toString().split("|");
      this.getAuthGdigital(array[0], array[1]);
    },

    // atualizar o auth (forms) da g digital
    getAuthGdigital(id_blog, auth_blog) {
      this.loading = true;
      const blog_id = +id_blog;
      const auth = auth_blog;
      const email = this.email || this.$store.state.user.user.email;
      const token = this.token;

      api_gdigital
        .get(
          `/auth?blog_id=${blog_id}&auth=${auth}&email_gdigital=${email}&token=${token}`
        )
        .then((r) => {
          this.integration = true;
          this.auth = r.data.auth;
          this.getFormsGdigital();
          this.$emit("codeGdigitalChecked");

          this.submit = false;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.submit = false;
          this.loading = false;
          this.integration = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // puxa todos os blogs do usuario na g digital
    getBlogsGdigital() {
      this.loading = true;
      const email = this.email || this.$store.state.user.user.email;

      this.blogs_gdigital = [];
      api_gdigital
        .get(`/blogs?email=${email}`)
        .then((response) => {
          [...response.data].forEach((item) => {
            this.blogs_gdigital.push({
              value: `${item.blog_id}|${item.auth}`,
              text: item.blogName || item.domain,
            });
          });

          if (!this.forms[0]) {
            this.getFormsGdigital();
          }

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          // this.$bvToast.toast("Erro ao carregar os dados da G Digital", {
          //   title: "G Digital",
          //   variant: "danger",
          //   autoHideDelay: 5000,
          //   appendToast: true,
          // });
        });
    },

    // valida o codigo de verificação que foi enviado no email
    validaCodigo() {
      this.$validator.validateAll("gdigital").then((result) => {
        if (result) {
          this.submit = true;
          this.loading = true;
          api_gdigital
            .get(`/token/${this.code}`)
            .then((r) => {
              this.token = r.data;
              this.integration = true;
              this.$bvToast.toast(
                this.$t('seller.products.gdigital.text_370'),
                {
                  title: "G Digital",
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                }
              );

              // começar ajustar daqui Novo fluxp
              this.getBlogsGdigital();
            })
            .catch((error) => {
              this.submit = false;
              this.loading = false;
              console.log(error);
              // this.$bvToast.toast(
              //   "Ocorreu um erro na Validação do Código (expirado ou inválido)",
              //   {
              //     title: "G Digital",
              //     variant: "danger",
              //     autoHideDelay: 5000,
              //     appendToast: true,
              //   }
              // );
            });
        }
      });
    },

    // envia o codigo de verificação com um novo email
    enviar_codigo() {
      this.reenviarCodigoGdigital();
      this.novo_email = false;
    },

    // reenvia o codigo de verificação
    reenviarCodigoGdigital() {
      this.emailGdigital();
      this.$bvToast.toast(this.$t('seller.products.gdigital.text_370'), {
        title: "G Digital",
        variant: "success",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },

    // envia email com o codigo de verificação
    emailGdigital() {
      this.loading = true;
      const email = this.email || this.$store.state.user.user.email;
      api_gdigital
        .get(`/email?email=${email}`)
        .then((r) => {
          this.g_email = true;
        })
        .catch((error) => {
          console.log(error);
          this.g_email = false;
          this.novo_email = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // abrindo modal e setando valores
    openModal() {
      this.loading = true;
      if (this.gdigital.integration) {
        this.integration = this.gdigital.integration;
        this.token = this.gdigital.token;
        this.email = this.gdigital.email;
        this.blogs_gdigital = this.gdigital.blogs_gdigital;
        this.loading = false;
      } else {
        this.emailGdigital();
      }
    },
  },
};
</script>

<style scoped>
.title-inter {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  color: $black;
}
.form-group + .form-group {
  margin-top: 15px;
}
/* // codigo de verificação g digital */
.codigo-verifica {
  max-width: 60%;
}
@media screen and (max-width: 768px) {
  .codigo-verifica {
    max-width: 100%;
  }
}
.codigo-verifica p {
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
  margin-bottom: 20px;
}
.sem-codigo {
  font-size: 14px;
  color: #333;
}
.sem-codigo:hover {
  font-weight: normal;
}
.grid-events {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 20px 0 10px;
}
.titulo-acao {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
}

.check-box .info-checkbox {
  font-size: 13px;
  font-weight: 500;
  margin-left: 5px;
  cursor: pointer;
  max-width: 250px;
  margin-top: 15px !important;
}
</style>
