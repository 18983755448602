<template>
  <div>
    <div v-if="!loading">
      <ul v-if="lista.length" class="mt-3">
        <li
          v-for="(item, index) in lista"
          :key="item.name + item.id"
          data-anima="bottom"
        >
          <div>
            <img
              src="@/assets/img/icons/memberkit-icon.svg"
              class="icon-file"
            />
            <div class="info-list">
              <span> #{{ item.id }} - {{ item.name }} </span>
            </div>
          </div>
          <div>
            <img
              src="@/assets/img/icons/lixeira.svg"
              class="icon-lixeira"
              @click="removeGdigital(index, item.id, item.name)"
            />
          </div>
        </li>
      </ul>

      <b-row key="nao-encontrado" v-else>
        <p class="nao-encontrado">
          {{ $t('seller.products.listagem_gdigital.text_526') }}
        </p>
      </b-row>
    </div>
    <div class="container-loading" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

// helper:
import { convertTrigger } from "@/helpers.js";
// services:
import CallbackService from "@/services/resources/CallbackService";
const serviceCallback = CallbackService.build();

export default {
  data() {
    return {
      loading: false,
      lista: [],
    };
  },
  props: {
    id_product: {
      type: Number,
    },
    integracao: {
      type: Boolean,
    },
  },

  methods: {
    // remove G Digital
    removeGdigital(index_lista, id, name) {
      Vue.swal({
        title: "Remover Evento ",
        text: `Deseja continuar com a remoção do #${id} - ${name}?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;

          let data = {
            id: id,
          };
          serviceCallback
            .destroy(data)
            .then((response) => {
              if (response == 1) {
                this.$bvToast.toast(this.$t('seller.products.listagem_gdigital.text_1947'), {
                  title: "G Digital",
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
                // atualizando front
                this.lista.splice(index_lista, 1);
              } else {
                this.$bvToast.toast(this.$t('seller.products.listagem_gdigital.text_1948'), {
                  title: "G Digital",
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
              }
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    // puxa todos os callbacks da g digital
    getCallbacks() {
      this.loading = true;
      let data = {
        product_id: this.id_product,
        type: "gdigital",
      };
      serviceCallback
        .search(data)
        .then((r) => {
          this.lista = [];
          [...r].forEach((item) => {
            if (item.type == "gdigital") {
              this.lista.push({
                name: convertTrigger(item.trigger),
                url: JSON.parse(item.args).url,
                id: item.id,
              });
            }
          });
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
  mounted() {
    if (this.integracao) {
      this.getCallbacks();
    }
  },
};
</script>

<style scoped>
p {
  margin: 0;
}
/* lista de entregas */
.lista-entrega h5 {
  margin-bottom: 20px;
  color: $black;
  font-weight: 600;
  font-size: 14px;
}
.lista-entrega ul,
.opcoes-entrega {
  display: flex;
  flex-direction: column;
}
.lista-entrega ul li,
.opcoes-entrega li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 30px;
  height: 70px;
  border-radius: 5px;
  background: rgba(247, 247, 247, 0.5);
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s;
}
.lista-arquivos ul li {
  padding: 13px 20px;
  font-size: 13px;
}
.opcoes-entrega li {
  padding: 20px 30px;
  height: 80px;
}
.lista-entrega ul li div {
  display: flex;
  align-items: center;
  font-size: 15px;
  text-transform: capitalize;
}
.opcoes-entrega li .grid-content {
  display: grid;
  grid-template-columns: 120px 1fr;
  align-items: center;
}
.lista-entrega ul li div img {
  width: 20px;
  margin-right: 30px;
}
.opcoes-entrega li div img {
  width: 20px;
  margin-right: 30px;
  filter: invert(50%);
}
.item-upload {
  position: relative;
  padding: 40px;
  border: 2px dashed #ededf0;
  border-radius: 5px;
  text-align: center;
}
.item-upload:hover {
  background: #f7f7f7;
}
.item-upload h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.item-upload p {
  font-size: 13px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
#arquivo-entrega {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  height: 80px;
  cursor: pointer;
  opacity: 0;
}
.opcoes-entrega li .infos h5,
.grid-content h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.grid-content h5 {
  margin: 0;
}
.opcoes-entrega li .infos p {
  font-size: 13px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
.opcoes-entrega li div img.logo-entrega {
  width: 60px;
}
.lista-entrega ul {
  max-height: 400px;
  overflow: auto;
}
.lista-arquivos ul {
  max-height: initial;
  overflow: initial;
}
.lista-entrega ul li .icon-lixeira,
.lista-entrega ul li .icon-editar {
  width: 14px;
  filter: invert(50%);
}
.lista-entrega ul li .icon-editar {
  margin-right: 15px !important;
}
.lista-entrega ul li:hover,
.opcoes-entrega li:hover {
  font-weight: 600;
  background: #f7f7f7;
}

.icon-lixeira:hover,
.icon-editar:hover {
  filter: invert(0) !important;
}
.lista-entrega ul li + li,
.opcoes-entrega li + li {
  margin-top: 10px;
}
/* // co-seller */
.co-seller-item {
  width: 90%;
}
.info-co-seller {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.info-co-seller h5 {
  margin: 0;
}
.info-co-seller .separacao-co-seller {
  display: block;
}
.info-co-seller .separacao-co-seller:last-child {
  text-align: right;
}
.info-co-seller .separacao-co-seller h5 {
  color: #333;
}
.info-co-seller .separacao-co-seller p {
  margin-top: 5px;
}
</style>
