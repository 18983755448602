<template>
  <BaseModal
    name="modal-googlesheets"
    size="lg"
    :title="$t('seller.products.google_sheets.text_371')"
    @shown="openModal"
    @hidden="closeModal"
  >
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <section class="container-arquivos" v-else>
      <div class="lista-entrega lista-arquivos mt-4">
        <div>
          <h4 class="title-inter">• Google Sheets</h4>
          <div class="d-flex mb-2" data-anima="top">
            <b-col cols="12" data-anima="top">
              <!-- <BaseButton
                variant="black"
                class="gerencia-conexoes mb-3"
                @click="newConnection = true"
                v-if="
                  gerenciar === false &&
                  !newConnection &&
                  selectedConection === null
                "
                >Adicionar Conexão</BaseButton
              > -->
              <div
                class="google-btn"
                @click="newConnection = true"
                v-if="
                  gerenciar === false &&
                    !newConnection &&
                    selectedConection === null
                "
              >
                <div class="google-icon-wrapper">
                  <img
                    class="google-icon"
                    src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                  />
                </div>
                <p class="btn-text"><b>{{ $t('seller.products.google_sheets.text_1906') }}</b></p>
              </div>
              <b-form-input
                v-if="newConnection"
                class="mb-3"
                v-model="nameConnection"
                :placeholder="$t('seller.products.google_sheets.text_1907')"
              ></b-form-input>
              <div class="d-flex">
                <BaseButton
                  variant="link-info"
                  class="gerencia-conexoes mt-3 mr-3"
                  @click="newConnection = false"
                  v-if="gerenciar === false && newConnection"
                >
                  {{ $t('seller.products.google_sheets.text_1908') }}
                </BaseButton>
                <BaseButton
                  variant="info3"
                  class="gerencia-conexoes mt-3"
                  @click="openWindowGoogle"
                  v-if="gerenciar === false && newConnection"
                  >{{ $t('seller.products.google_sheets.text_1909') }}</BaseButton
                >
              </div>
              <iframe
                v-if="openIntFrame"
                height="0"
                width="0"
                style="display: none"
                :src="srcIframe"
              ></iframe>
            </b-col>
          </div>
          <div
            v-if="googlesheets.length === 0 && gerenciar === false"
            data-anima="top"
          >
            <p>
              {{ $t('seller.products.google_sheets.text_372') }}
            </p>
            <p>
              {{ $t('seller.products.google_sheets.text_373') }}
            </p>
            <br />
            <p>{{ $t('seller.products.google_sheets.text_374') }}</p>
          </div>
          <b-col
            cols="12"
            data-anima="top"
            v-if="googlesheets.length !== 0 && !newConnection"
          >
            <ul class="mt-3" v-if="selectedConection === null">
              <li
                v-for="(item, index) in googlesheets"
                :key="item.title + item.id"
                data-anima="bottom"
              >
                <div @click="selectItem(item.id)">
                  <img
                    src="@/assets/img/icons/checkItem.svg"
                    class="icon-file"
                  />
                  <div class="info-list">
                    <span>
                      #{{ item.id }} - {{ item.title }}
                      <p data-anima="bottom" class="ativo">
                        {{ $t('seller.products.google_sheets.text_375') }} {{ item.created_at | datetime }}
                      </p>
                    </span>
                  </div>
                </div>
                <div class="acoes-webhook">
                  <img
                    src="@/assets/img/icons/lixeira.svg"
                    class="icon-lixeira"
                    :id="'btn-remover-' + item.id"
                    @click="removeConection(item.id)"
                  />
                  <b-tooltip
                    :target="'btn-remover-' + item.id"
                    :title="$t('seller.products.google_sheets.text_1910')"
                    placement="top"
                  />
                </div>
              </li>
            </ul>

            <ul v-else>
              <li data-anima="bottom">
                <div>
                  <img
                    src="@/assets/img/icons/checkItem.svg"
                    class="icon-file"
                  />
                  <div class="info-list">
                    <span>
                      #{{ selectedConection }} -
                      {{ filterItem(selectedConection)[0].name }}
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </b-col>
          <b-col
            cols="12"
            class="mt-4"
            data-anima="top"
            v-if="selectedConection"
          >
            <b-form-group label="Tipo para Ação" label-for="check">
              <b-form-select v-model="selectedTypeAction" class="mb-3">
                <b-form-select-option :value="null"
                  >{{ $t('seller.products.google_sheets.text_376') }}</b-form-select-option
                >
                <b-form-select-option value="assinatura"
                  >{{ $t('seller.products.google_sheets.text_377') }}</b-form-select-option
                >
                <b-form-select-option value="venda">{{ $t('seller.products.google_sheets.text_1911') }}</b-form-select-option>
                <b-form-select-option value="checkout"
                  >{{ $t('seller.products.google_sheets.text_378') }}</b-form-select-option
                >
              </b-form-select>
            </b-form-group>

            <h6 class="titulo-acao" v-if="selectedTypeAction !== null">
              {{ $t('seller.products.google_sheets.text_379') }}
            </h6>
            <div class="grid-events">
              <b-form-group
                v-if="selectedTypeAction === 'venda'"
                :label="$t('seller.products.google_sheets.text_1912')"
                label-for="check"
              >
                <b-form-checkbox
                  size="md"
                  switch
                  class="check-box mb-1"
                  name="check"
                  v-for="op in options_sales"
                  :key="op.value"
                  @change="triggerChanged(op.value)"
                >
                  <p class="info-checkbox">{{ op.text }}</p>
                </b-form-checkbox>
              </b-form-group>
              <b-form-group
                v-if="selectedTypeAction === 'assinatura'"
                :label="$t('seller.products.google_sheets.text_377')"
                label-for="check"
              >
                <b-form-checkbox
                  size="md"
                  switch
                  class="check-box mb-2"
                  name="check"
                  v-for="op in options_sub"
                  :key="op.value"
                  @change="triggerChanged(op.value)"
                >
                  <p class="info-checkbox">{{ op.text }}</p>
                </b-form-checkbox>
              </b-form-group>
              <!-- <b-form-group label="Ação" label-for="check" data-anima="top">
                <b-form-checkbox
                  size="md"
                  switch
                  class="check-box mb-2"
                  name="check"
                  v-for="op in options_callback"
                  :key="op.value"
                  v-model="op.type"
                  @change="triggerCallbackChanged(op)"
                >
                  <p class="info-checkbox">{{ op.text }}</p>
                </b-form-checkbox>
              </b-form-group> -->
              <b-form-group
                label="Checkout"
                v-if="selectedTypeAction === 'checkout'"
                label-for="check"
              >
                <b-form-checkbox
                  size="md"
                  switch
                  class="check-box mb-2"
                  name="check"
                  v-for="op in options_checkout"
                  :key="op.value"
                  @change="triggerChanged(op.value)"
                >
                  <p class="info-checkbox">{{ op.text }}</p>
                </b-form-checkbox>
              </b-form-group>
            </div>
          </b-col>
          <span>
            <b-col
              cols="12"
              v-if="
                googlesheets.length !== 0 &&
                  selectedConection !== null &&
                  makePlanilha === false
              "
            >
              <b-form-group
                :label="$t('seller.products.google_sheets.text_380')"
                label-for="form_list_active"
              >
                <multiselect
                  v-model="lista"
                  :options="lista_options"
                  :value="lista"
                  :multiple="false"
                  :close-on-select="true"
                  :clearOnSelect="true"
                  :preserve-search="true"
                  :placeholder="$t('seller.products.google_sheets.text_380')"
                  label="text"
                  track-by="text"
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel="✔️"
                  id="multi_select_list_active"
                  name="list_active"
                  :custom-label="customLabel"
                >
                  <template slot="afterList">
                    <div v-observe-visibility="reachedEndOfList" />
                  </template>
                </multiselect>
                <b-form-invalid-feedback :state="!errors.has('list_active')">
                  {{ $t('seller.products.google_sheets.text_1913') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              v-if="
                googlesheets.length !== 0 &&
                  selectedConection !== null &&
                  makePlanilha === false
              "
            >
              <!-- <BaseButton
                variant="danger"
                class="gerencia-conexoes mt-3 mr-3"
                v-if="lista"
                @click="deletePlanilha"
                >Deletar Planilha</BaseButton
              > -->
              <BaseButton
                variant="info3"
                class="gerencia-conexoes mt-3"
                @click="makePlanilha = true"
                >Nova Planilha</BaseButton
              >
            </b-col>
            <b-col
              cols="12"
              v-if="
                googlesheets.length !== 0 &&
                  selectedConection !== null &&
                  makePlanilha === true
              "
            >
              <b-form-group
                :label="$t('seller.products.google_sheets.text_1914')"
                label-for="form_list_active"
              >
                <b-form-input
                  v-model="newPlanilha"
                  :placeholder="$t('seller.products.google_sheets.text_1915')"
                ></b-form-input>
              </b-form-group>

              <BaseButton
                variant="link-info"
                class="gerencia-conexoes mt-3 mr-3"
                @click="makePlanilha = false"
                >{{ $t('seller.products.google_sheets.text_1908') }}</BaseButton
              >

              <BaseButton
                variant="info3"
                class="gerencia-conexoes mt-3"
                @click="novaPlanilha"
                >{{ $t('seller.products.google_sheets.text_1916') }}</BaseButton
              >
            </b-col>
          </span>
        </div>
        <div class="d-flex justify-content-center" v-if="loading">
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </div>
    </section>

    <!-- Modal conexoes -->
    <Conexoes @close="fetchConnection" />

    <template v-slot:footer="{ cancel }">
      <BaseButton
        :disabled="loading"
        variant="link-info"
        class="mr-4"
        @click="cancel"
      >
        {{ $t('seller.products.google_sheets.text_1918') }}
      </BaseButton>
      <BaseButton
        v-if="
          gerenciar === false &&
            googlesheets.length !== 0 &&
            selectedConection !== null
        "
        variant="info3"
        :disabled="loading"
        @click="createCallback"
      >
        {{ $t('seller.products.google_sheets.text_1917') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import Conexoes from "../../MyAccount/modals/Conexoes";
import Cookies from "js-cookie";
// services:
import ConnectionService from "@/services/resources/ConnectionService";
const serviceConnection = ConnectionService.build();

import GoogleSheetsService from "@/services/resources/GoogleSheetsService";
const serviceGoogleSheets = GoogleSheetsService.build();

import Multiselect from "vue-multiselect";
import CallbackService from "@/services/resources/CallbackService";
const serviceCallback = CallbackService.build();
import { convertTrigger } from "@/helpers.js";

export default {
  data() {
    return {
      selectedTypeAction: null,
      makePlanilha: false,
      newPlanilha: "",
      nameConnection: "",
      newConnection: false,
      optionsConection: [{ id: null, name: this.$t('seller.products.google_sheets.text_384') }],
      selectedConection: null,
      openIntFrame: false,
      srcIframe: "",
      loading: false,
      submit: false,
      googlesheets: {},
      connection_name: null,
      connection_api_key: null,
      connection_base_url: null,
      lista_options: [],
      lista_options_prev: null,
      lista_options_next: null,
      select_callback: "googleSheet",
      trigger: [],
      callback: [],
      lista: null,
      gerenciar: false,
      multiselect: true,
      options_sales: [
        {
          value: "salePaid",
          text: "Venda Paga",
        },
        {
          value: "saleRefused",
          text: "Venda Recusada",
        },
        {
          value: "saleRefunded",
          text: "Venda Reembolsada",
        },
        {
          value: "saleChargedback",
          text: "Chargeback realizado",
        },
        {
          value: "saleUnpaid",
          text: "Venda não paga",
        },
        {
          value: "saleWaitingPayment",
          text: "Venda aguardando pagamento",
        },
      ],
      options_sub: [
        {
          value: "contractUnpaid",
          text: "Assinatura não paga",
        },
        {
          value: "contractPaid",
          text: "Assinatura paga",
        },
        {
          value: "contractTrialing",
          text: "Assinatura em Trial",
        },
        {
          value: "contractPendingPayment",
          text: "Assinatura aguardando pagamento",
        },
        {
          value: "contractEnded",
          text: "Assinatura Finalizada",
        },
        {
          value: "contractCanceled",
          text: "Assinatura Cancelada",
        },
      ],
      options_checkout: [
        {
          value: "checkoutAbandoned",
          text: "Checkout Abandonado",
        },
      ],
      options_callback: [
        {
          value: "googleSheet",
          text: "Cadastrar",
          type: true,
        },
        {
          value: "removerGoogle Sheets",
          text: "Remover",
          type: false,
        },
      ],
    };
  },
  props: {
    id_product: {
      type: Number,
    },
  },
  components: {
    Conexoes,
    Multiselect,
  },
  methods: {
    deletePlanilha() {
      console.log(this.lista);
      this.loading = true;

      let data = {
        connection_id: this.selectedConection,
        id_file: this.lista.id,
      };

      serviceGoogleSheets
        .destroyAll(data)
        .then((response) => {
          this.loading = false;
          this.lista = null;
          this.fetchLists();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    novaPlanilha() {
      // this.loading = true;
      let data = {
        id: `?connection_id=${this.selectedConection}`,
        title: this.newPlanilha,
        type: this.selectedTypeAction,
      };

      serviceGoogleSheets
        .createId(data)
        .then((response) => {
          console.log("get list sheets", response);
          this.lista_options = [];
          this.newPlanilha = "";
          this.lista_options_next = null;
          this.lista_options_prev = null;
          this.makePlanilha = false;
          this.fetchLists("first");
          // this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.makePlanilha = false;
        })
        .finally(() => {
          // this.loading = false;
        });
    },
    filterItem(id, type) {
      let myArray = this.optionsConection;
      return myArray.filter((x) => x.id === id);
    },
    selectItem(id) {
      this.selectedConection = id;
      this.fetchLists();
    },
    removeConection(id) {
      this.loading = true;
      serviceConnection
        .destroy(id)
        .then((response) => {
          this.loading = false;
          this.fetchConnection();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openWindowGoogle() {
      const assign =
        "access_token" + "=" + escape(Cookies.get("access_token")) + ";";
      const d = new Date();
      d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
      const expires = "expires=" + d.toUTCString() + ";";
      const path = "path=/;";
      var domain = "domain=" + "app.voompplay.com.br" + ";";

      document.cookie = assign + expires + path + domain;
      this.srcIframe =
        window.location.origin +
        "/login-to-google/" +
        Cookies.get("access_token") +
        "/" +
        this.nameConnection;

      setTimeout(() => {
        this.openIntFrame = true;
        this.newConnection = false;
        this.intervalConnections();
      }, 3000);
    },
    intervalConnections() {
      var that = this;
      this.intervalid1 = setInterval(function() {
        that.fetchConnection();
      }, 5000);
    },
    triggerCallbackChanged(v) {
      if (v.value === "googleSheet") {
        this.options_callback[1].type = false;
        this.options_callback[0].type = true;
      } else {
        this.options_callback[0].type = false;
        this.options_callback[1].type = true;
      }

      if (this.options_callback[0].type === true) {
        this.select_callback = v.value;
      }

      if (this.options_callback[1].type === true) {
        this.select_callback = v.value;
      }

      console.log(this.select_callback);
    },
    resetInputs() {
      this.googlesheets = {};
      this.connection_name = null;
      this.connection_api_key = null;
      this.connection_base_url = null;
      this.lista_options = [];
      this.tag_options = [];
      this.tag = null;
      this.openIntFrame = false;
      this.options_callback[0].type = true;
      this.options_callback[1].type = false;
      this.select_callback = "googleSheet";
      this.trigger = [];
      this.lista = null;
      this.gerenciar = false;
    },
    // adicionar uma nova callback
    createCallback() {
      return new Promise((resolve) => {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.submit = true;
            this.loading = true;
            if (this.lista !== null) {
              let data = {
                product_id: this.id_product,
                table_name: this.lista.name,
                trigger: this.connection_api_key,
                callback: "googleSheet",
                type: "google",
                id_file: this.lista.id,
                connection_id: this.selectedConection,
              };

              if (this.trigger.length) {
                this.trigger.forEach((event) => {
                  data.trigger = "";
                  setTimeout(async () => {
                    data.trigger = event;
                    await this.postCallback(data, event);
                    resolve(true);
                  }, 500);
                });
              } else {
                this.loading = false;
                this.$bvToast.toast(this.$t('seller.products.google_sheets.text_381'), {
                  title: "Google Sheets",
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
                resolve(false);
              }
            } else {
              this.loading = false;
              this.$bvToast.toast(this.$t('seller.products.google_sheets.text_382'), {
                title: "Google Sheets",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
              resolve(false);
            }
          } else {
            resolve(false);
          }
        });
      });
    },
    async postCallback(data, event) {
      serviceCallback
        .create(data)
        .then(() => {
          this.$bvToast.toast(
            `[${convertTrigger(event)}] ${this.$t('seller.products.google_sheets.text_383')}`,
            {
              title: "Google Sheets",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
          this.resetInputs();
          this.$bvModal.hide("modal-googlesheets");
          setTimeout(() => {
            this.$emit("close");
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.submit = false;
          this.loading = false;
        });
    },
    // checkbox dos eventos
    triggerChanged(v) {
      if (this.trigger.includes(v)) {
        this.trigger.splice(this.trigger.indexOf(v), 1);
      } else {
        this.trigger.push(v);
      }
    },
    customLabelTags({ tag }) {
      return `${tag}`;
    },
    customLabel({ name }) {
      return `${name}`;
    },
    reachedEndOfList(reached) {
      if (reached) {
        this.fetchLists(this.lista_options_next);
      }
    },
    fetchLists(page) {
      let data = {
        connection_id: this.selectedConection,
        type: "google",
      };

      if (page && page !== "first") {
        data.page = page;
      }

      serviceGoogleSheets
        .search(data)
        .then((response) => {
          console.log("get list sheets", response);
          if (page && page !== "first") {
            this.lista_options.push(response.files);
          } else {
            this.lista_options = response.files;
          }
          if (page === "first") {
            this.lista = response.files[0];
          }

          this.lista_options_next = response.nextPageToken;
          this.lista_options_prev = response.prevPageToken;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchConnection() {
      this.optionsConection = [];
      serviceConnection
        .search()
        .then((response) => {
          this.googlesheets = response;
          response.forEach((element) => {
            this.optionsConection.push({
              id: element.id,
              name: element.title,
            });
          });
          this.connection_name = response.title;
          (this.connection_api_key = response.apiToken),
            (this.connection_base_url = response.base_url);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // abrindo modal e setando valores
    openModal() {
      this.loading = true;
      // puxando as conexões do googlesheets
      this.fetchConnection();
    },
    closeModal() {
      clearInterval(this.intervalid1);
      this.selectedConection = null;
      this.resetInputs();
      this.optionsConection = [{ id: null, name: this.$t('seller.products.google_sheets.text_384') }];
    },
  },
};
</script>

<style scoped>
@import url(https://fonts.googleapis.com/css?family=Roboto:500);
.google-btn {
  width: 200px;
  height: 42px;
  background-color: #4285f4;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
}
.google-btn .google-icon-wrapper {
  position: absolute;
  margin-top: 1px;
  margin-left: 0px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: #fff;
}
.google-btn .google-icon {
  position: absolute;
  margin-top: 11px;
  margin-left: 11px;
  width: 18px;
  height: 18px;
}
.google-btn .btn-text {
  float: right;
  margin: 11px 11px 0 0;
  color: #fff !important;
  font-size: 14px;
  letter-spacing: 0.2px;
  font-family: "Roboto";
}
.google-btn .btn-text b {
  color: #fff !important;
}
.google-btn:hover {
  box-shadow: 0 0 6px #4285f4;
}
.google-btn:active {
  background: #1669f2;
}

p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
.title-inter {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #2a63ab;
}
.form-group + .form-group {
  margin-top: 15px;
}
.gerencia-conexoes {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}

.codigo-verifica p {
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
  margin-bottom: 20px;
}
.sem-codigo {
  font-size: 14px;
  color: #333;
}
.sem-codigo:hover {
  font-weight: normal;
}
.grid-events {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 20px 0 10px;
}
.titulo-acao {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
}

.check-box .info-checkbox {
  font-size: 13px;
  font-weight: 500;
  margin-left: 5px;
  cursor: pointer;
  max-width: 250px;
  margin-top: 15px !important;
}
.top {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.top a {
  font-size: 14px;
  color: #81858e;
}
.lista-entrega h5 {
  color: $black;
  font-weight: 600;
  font-size: 14px;
}
.lista-entrega ul,
.opcoes-entrega {
  display: flex;
  flex-direction: column;
}
.lista-entrega ul li,
.opcoes-entrega li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 30px;
  height: 70px;
  border-radius: 5px;
  background: rgba(247, 247, 247, 0.5);
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s;
}
@media screen and (max-width: 667px) {
  .lista-entrega ul li,
  .opcoes-entrega li {
    display: block;
    height: 100px;
  }
  .acoes-webhook {
    margin-left: 30px;
    margin-top: 7px;
  }
}
.lista-arquivos ul li {
  padding: 13px 20px;
  font-size: 13px;
}
.opcoes-entrega li {
  padding: 20px 30px;
  height: 80px;
}
.lista-entrega ul li div {
  display: flex;
  align-items: center;
  font-size: 15px;
}
.opcoes-entrega li .grid-content {
  display: grid;
  grid-template-columns: 120px 1fr;
  align-items: center;
}
.lista-entrega ul li div img.icon-file {
  width: 18px;
  margin-right: 30px;
}
.lista-entrega ul li div img + img {
  margin-left: 20px;
}
.opcoes-entrega li div img {
  width: 20px;
  margin-right: 30px;
  filter: invert(50%);
}
.item-upload {
  position: relative;
  padding: 40px;
  border: 2px dashed #ededf0;
  border-radius: 5px;
  text-align: center;
}
.item-upload:hover {
  background: #f7f7f7;
}
.item-upload h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.item-upload p,
.info-lista {
  font-size: 13px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
#arquivo-entrega {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  height: 80px;
  cursor: pointer;
  opacity: 0;
}
.opcoes-entrega li .infos h5,
.grid-content h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.grid-content h5 {
  margin: 0;
}
.opcoes-entrega li .infos p {
  font-size: 13px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
.opcoes-entrega li div img.logo-entrega {
  width: 60px;
}
.lista-entrega ul {
  max-height: 300px;
  overflow: auto;
}
.lista-arquivos ul {
  max-height: initial;
  overflow: initial;
}

.lista-entrega ul li .icon-editar {
  margin-right: 15px !important;
}
.lista-entrega ul li:hover,
.opcoes-entrega li:hover {
  font-weight: 600;
  background: #f7f7f7;
}
@media screen and (max-width: 667px) {
  .lista-entrega ul li:hover,
  .opcoes-entrega li:hover {
    font-weight: 500;
    background: #f7f7f7;
  }
}

.lista-entrega ul li + li,
.opcoes-entrega li + li {
  margin-top: 10px;
}

/* edição web */
.info-list {
  position: relative;
}
.info-list span {
  font-size: 14px;
}
.info-list p.ativo {
  text-transform: initial;
  font-size: 12px;
  margin-top: 3px;
  color: #81858e;
  display: block;
}
.info-list p {
  display: none;
}

.input-edit-webhook {
  height: 20px !important;
  width: 400px;
  padding: 5px 0 15px 0 !important;
  font-size: 12px !important;
  margin-bottom: -2px !important;
  margin-top: 10px !important;
  background: transparent;
  border: none !important;
  border-bottom: 1px solid #81858e !important;
  border-radius: 0 !important;
  display: none;
}
@media screen and (max-width: 667px) {
  .input-edit-webhook {
    width: 250px;
  }
}
.input-edit-webhook.ativo {
  display: block;
}
.btn-edit-web {
  display: none;
}
.btn-edit-web.ativo {
  display: block;
}

/* 
 */
.lista-entrega ul li .icon-lixeira,
.lista-entrega ul li .icon-editar {
  width: 14px;
  filter: invert(50%);
  /* padding: 10px; */
}
.icon-lixeira:hover,
.icon-editar:hover {
  filter: invert(0) !important;
}
.info-qtd {
  margin-top: -15px;
}
</style>
