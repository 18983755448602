<template>
  <div class="mb-3">
    <div v-if="!loading && lista.length">
      <div class="top">
        <h5>• MemberKit</h5>
        <a href="#" @click.prevent="showList = !showList">{{
          showList ? "ocultar" : "mostrar"
        }}</a>
      </div>
      <ul class="mt-3" v-show="showList">
        <li
          v-for="item in lista"
          :key="item.id + item.name"
          data-anima="bottom"
        >
          <div>
            <img
              src="@/assets/img/icons/memberkit-icon.svg"
              class="icon-file"
            />
            <div class="info-list">
              <span> #{{ item.id }} - {{ item.name }} </span>
            </div>
          </div>
          <div>
            <img
              src="@/assets/img/icons/lixeira.svg"
              class="icon-lixeira"
              @click="removeMemberkit(item.id, item.name)"
            />
          </div>
        </li>
      </ul>
      <div v-if="!showList && lista.length">
        <p class="info-lista">
          ✨ {{ lista.length }} {{ $t('seller.products.listagem_memberkit.text_529') }}
        </p>
      </div>
    </div>
    <div class="container-loading" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

// helper:
import { EventBus } from "@/main.js";

// services:
import IntegrationService from "@/services/resources/IntegrationService";
const serviceIntegration = IntegrationService.build();

export default {
  data() {
    return {
      loading: false,
      showList: true,
      regras: [],
      lista: [],
    };
  },
  props: {
    id_product: {
      type: Number,
    },
  },

  methods: {
    // remove item do memberkit
    removeMemberkit(id, name) {
      let texto = "";
      if (name) {
        if (name.indexOf("|") > -1) {
          texto = `Ao remover essa integração, as turmas vinculadas a #${id} também serão removidas`;
        } else {
          texto = "Deseja continuar com a Remoção dessa Assinatura?";
        }
      } else texto = "Deseja continuar com a Remoção desse item?";

      Vue.swal({
        title: "Remover entrega ",
        text: texto,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          let data = {
            id: id,
          };

          serviceIntegration
            .destroy(data)
            .then((response) => {
              if (response.success) {
                this.$bvToast.toast("Integração removida com sucesso!", {
                  title: "Memberkit",
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
                // atualizando front
                // this.lista.splice(index_lista, 1);
                this.lista = [...this.lista].filter((item) => {
                  if (item.id != data.id) {
                    return item;
                  }
                });
                EventBus.$emit("integracoesLista", -1);
              } else {
                this.$bvToast.toast("Houve um erro ao remover a Integração", {
                  title: "Memberkit",
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
              }
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    // puxando todas as regras de liberação
    getRegrasLiberacao() {
      return new Promise((resolve) => {
        // forma antiga sem filtragem
        // let data = {
        //   id: this.id_product,
        // };

        // forma nova, passando filtro
        let data = {
          product_id: this.id_product,
          type: "memberkit",
          url: "list",
        };
        serviceIntegration
          // forma antiga, sem filtro
          // .read(data)
          // forma nova, passando filtro
          .search(data)
          .then((response) => {
            this.regras = response;
            resolve(true);
          })
          .catch((error) => {
            console.log(error);
            this.regras = [];
            resolve(false);
          });
      });
    },

    // inciando puxando as regras
    initRegras() {
      this.loading = true;
      // função que puxa todas as regras
      this.getRegrasLiberacao().then((r) => {
        if (r) {
          this.lista = [];
          if (this.regras.length) {
            [...this.regras].reverse().forEach((regra) => {
              // verificando tipo
              if (regra.type == "memberkit") {
                if (regra.data.access == "subscription") {
                  this.lista.push({
                    name: regra.data.membership_name,
                    id: regra.id,
                    connection_id: regra.data.connection_id,
                    access: regra.data.access,
                  });
                } else {
                  regra.classroom.forEach((turma) => {
                    this.lista.push({
                      name: turma.name,
                      id: regra.id,
                      connection_id: regra.data.connection_id,
                      access: regra.data.access,
                    });
                  });
                }
              }
            });
          }
        }
        this.loading = false;
      });
    },
  },
  mounted() {
    // iniciando puxando as regras
    this.initRegras();
  },
};
</script>

<style scoped>
p {
  margin: 0;
}
/* lista de entregas */
.lista-entrega h5 {
  margin-bottom: 20px;
  color: $black;
  font-weight: 600;
  font-size: 14px;
}
.lista-entrega ul,
.opcoes-entrega {
  display: flex;
  flex-direction: column;
}
.lista-entrega ul li,
.opcoes-entrega li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 30px;
  height: 70px;
  border-radius: 5px;
  background: rgba(247, 247, 247, 0.5);
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s;
}
.lista-arquivos ul li {
  padding: 13px 20px;
  font-size: 13px;
}
.opcoes-entrega li {
  padding: 20px 30px;
  height: 80px;
}
.lista-entrega ul li div {
  display: flex;
  align-items: center;
  font-size: 15px;
  text-transform: capitalize;
}
.opcoes-entrega li .grid-content {
  display: grid;
  grid-template-columns: 120px 1fr;
  align-items: center;
}
.lista-entrega ul li div img {
  width: 20px;
  margin-right: 30px;
}
.opcoes-entrega li div img {
  width: 20px;
  margin-right: 30px;
  filter: invert(50%);
}
.item-upload {
  position: relative;
  padding: 40px;
  border: 2px dashed #ededf0;
  border-radius: 5px;
  text-align: center;
}
.item-upload:hover {
  background: #f7f7f7;
}
.item-upload h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.item-upload p {
  font-size: 13px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
#arquivo-entrega {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  height: 80px;
  cursor: pointer;
  opacity: 0;
}
.opcoes-entrega li .infos h5,
.grid-content h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.grid-content h5 {
  margin: 0;
}
.opcoes-entrega li .infos p {
  font-size: 13px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
.opcoes-entrega li div img.logo-entrega {
  width: 60px;
}
.lista-entrega ul {
  max-height: 300px;
  overflow: auto;
}
.lista-arquivos ul {
  max-height: initial;
  overflow: initial;
}
.lista-entrega ul li .icon-lixeira,
.lista-entrega ul li .icon-editar {
  width: 14px;
  filter: invert(50%);
}
.lista-entrega ul li .icon-editar {
  margin-right: 15px !important;
}
.lista-entrega ul li:hover,
.opcoes-entrega li:hover {
  font-weight: 600;
  background: #f7f7f7;
}

.icon-lixeira:hover,
.icon-editar:hover {
  filter: invert(0) !important;
}
.lista-entrega ul li + li,
.opcoes-entrega li + li {
  margin-top: 10px;
}
/* // co-seller */
.co-seller-item {
  width: 90%;
}
.info-co-seller {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.info-co-seller h5 {
  margin: 0;
}
.info-co-seller .separacao-co-seller {
  display: block;
}
.info-co-seller .separacao-co-seller:last-child {
  text-align: right;
}
.info-co-seller .separacao-co-seller h5 {
  color: #333;
}
.info-co-seller .separacao-co-seller p {
  margin-top: 5px;
}
</style>
