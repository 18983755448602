<template>
  <BaseModal
    name="modal-contaazul"
    size="lg"
    title="Adicione a entrega via Conta Azul"
    @shown="openModal"
  >
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <section class="container-arquivos" v-else>
      <div>
        <div v-if="!contaazul.has_connection" class="d-flex justify-content-center">     
          <div id="toolbar-conexao">
            <BaseButton variant="info3" @click="openConexoes">Adicionar conexão</BaseButton>
            <BaseButton variant="info3" :disabled="!contaazul.data.connection_id" @click="removeConnection">Remover conexão</BaseButton>
          </div>
        </div>
        <b-form
          data-vv-scope="contaazul"
          v-if="contaazul.options_conexao.length"
        >
          <b-row>
            <b-col class="mr-3">
                <b-form-group label="Conexão" label-for="conexao">
                <b-form-select
                  v-model="contaazul.data.connection_id"
                  :options="contaazul.options_conexao"
                  name="conexao"
                  id="conexao"
                  placeholder="Selecione a Conexão"
                  v-validate="'required'"
                  :disabled="contaazul.has_connection"
                  @change="changeConnection()"
                >
                </b-form-select>
                <b-form-invalid-feedback
                  :state="!errors.has('contaazul.conexao')"
                >
                  Selecione a conexão
                </b-form-invalid-feedback>
              </b-form-group>                
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <h4 class="title-inter">Quando acontecer o(s) evento(s)</h4>
              <div class="grid-events">
                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Vendas" label-for="check">
                      <b-form-checkbox
                        size="md"
                        switch
                        class="check-box mb-2"
                        v-for="(op, index) in events.options_sales"
                        :name="`check-${index}`"
                        :id="`check-${index}`"
                        v-model="selectEvent[index]"
                        :key="op.value"
                        @change="triggerChanged(op.value)"
                      >
                        <p class="info-checkbox">{{ op.text }}</p>
                        <div
                          v-show="op.value == 'saleRefused' && selectEvent[1]"
                          data-anima="top"
                          class="mt-3 mb-3"
                        >
                          <b-form-checkbox
                            size="md"
                            switch
                            v-for="(sub, index) in events.options_salesRefused"
                            class="check-box mb-2"
                            :name="`check-refunsed-${index}`"
                            :id="`check-refunsed-${index}`"
                            :key="sub.value"
                            v-model="selectEventRefunsed[index]"
                            @change="subTriggerChanged(sub.value, 'saleRefused')"
                          >
                            <p class="info-checkbox">{{ sub.text }}</p>
                          </b-form-checkbox>
                        </div>
                        <!-- <div
                          v-show="op.value == 'saleWaitingPayment' && selectEvent[1]"
                          data-anima="top"
                          class="mt-3 mb-3"
                        >
                          <b-form-checkbox
                            size="md"
                            switch
                            v-for="(sub, index) in events.options_salesWaiting"
                            class="check-box mb-2"
                            :name="`check-waiting-${index}`"
                            :id="`check-waiting-${index}`"
                            :key="sub.value"
                            v-model="selectEventWaiting[index]"
                            @change="
                              subTriggerChanged(sub.value, 'saleWaitingPayment')
                            "
                          >
                            <p class="info-checkbox">{{ sub.text }}</p>
                          </b-form-checkbox>
                        </div> -->
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <!-- TODO: API CONTA AZUL CONTRACTS INTEGRATION -->
                  <!-- <b-col>
                    <b-form-group label="Assinatura" label-for="check">
                      <b-form-checkbox
                        size="md"
                        switch
                        class="check-box mb-2"
                        name="check"
                        v-for="op in events.options_sub"
                        :key="op.value"
                        @change="triggerChanged(op.value)"
                      >
                        <p class="info-checkbox">{{ op.text }}</p>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col> -->
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-form>
        <div v-else>
          <p>
            Verificamos que não há conexões com o Conta Azul vinculadas em sua
            conta.
          </p>
          <p>
            Por gentileza ajuste a conexão para seguir o fluxo e adicionar uma
            integração com o Conta Azul.
          </p>
          <br />
          <p>Clique no botão acima para gerenciar suas conexões</p>
        </div>
      </div>
    </section>

    <!-- Modal conexoes -->
    <Conexoes @close="fetchConnections" />

    <template v-slot:footer="{ cancel }">
      <BaseButton
        :disabled="loading"
        variant="link-info"
        class="mr-4"
        @click="cancel"
      >
        Cancelar
      </BaseButton>
      <BaseButton
        v-if="contaazul.options_conexao.length"
        variant="info3"
        :disabled="loading || !contaazul.data.connection_id || events.trigger.length == 0"
        @click="adicionarContaAzul"
      >
        Criar Liberação
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import Vue from "vue";

import Multiselect from "vue-multiselect";
import Conexoes from "../../MyAccount/modals/Conexoes";

// services:
import ConnectionService from "@/services/resources/ConnectionService";
const serviceConnection = ConnectionService.build();

import CallbackService from "@/services/resources/CallbackService";
const serviceCallback = CallbackService.build();

import ReleaseRuleService from "@/services/resources/ReleaseRuleService";
const serviceReleaseRule = ReleaseRuleService.build();

import ContaAzulService from "@/services/resources/ContaAzulService";
const serviceContaAzul = ContaAzulService.build();

export default {
  data() {
    return {
      loading: false,
      submit: false,
      contaazul: {
        data: {
          type: "contaazul",
          product_id: null,
          connection_id: null,
          connection_name: null,
        },
        options_conexao: [],
        options: [],
        object: [],
        callbacks: [],
        has_connection: true,
      },
      selectEvent: [],
      selectEventRefunsed: [],
      selectEventWaiting: [],
      init_click: false,
      events: {
        trigger: [],
        sub_trigger: [],
        sub_trigger2: [],
        callback: "executarContaazul",
        options_sales: [
          // {
          //   value: "saleUnpaid",
          //   text: "Venda não paga",
          // },
          // {
          //   value: "saleWaitingPayment",
          //   text: "Venda aguardando pagamento",
          // },
          {
            value: "salePaid",
            text: "Venda Paga",
          },
          {
            value: "saleRefused",
            text: "Venda Recusada",
          },
          {
            value: "saleRefunded",
            text: "Venda Reembolsada",
          },
          {
            value: "saleChargedback",
            text: "Chargeback realizado",
          },
        ],
        options_salesWaiting: [
          {
            value: "TODOS",
            text: "Todos",
          },
          {
            value: "BOLETO",
            text: "Boleto",
          },
          {
            value: "PIX",
            text: "PIX",
          },
        ],
        options_salesRefused: [
          {
            value: "TODOS",
            text: "Todos",
          },
          {
            value: "INVALID_CREDIT_CARD",
            text: "Cartão inválido/bloqueado",
          },
          {
            value: "INSUFFICIENT_FUNDS",
            text: "Cartão sem limite",
          },
          {
            value: "INVALID_PAYMENT_TYPE",
            text: "Método de pagamento inválido",
          },

          {
            value: "INVALID_DATA",
            text: "Dados do cartão inválidos",
          },
          {
            value: "INVALID_CLIENT_DATA",
            text: "Dados do cliente inválidos",
          },
          {
            value: "INVALID_CVV",
            text: "CVV do cartão inválido",
          },
          {
            value: "BANK",
            text: "Problema: Banco emissor do cartão",
          },
          {
            value: "CREDIT_CARD_OPERATOR",
            text: "Problema: Operadora do cartão",
          },

          {
            value: "INVALID_INSTALLMENTS",
            text: "Qtd parcelas não autorizada",
          },
          {
            value: "CURRENCY_NOT_SUPPORTED",
            text: "Moeda não autorizada",
          },
          {
            value: "SUSPECTED_FRAUD",
            text: "Suspeita de Fraude",
          },
          {
            value: "GENERIC",
            text: "Erro ao processar a compra",
          },
        ],
        options_sub: [
          // {
          //   value: "contractUnpaid",
          //   text: "Assinatura não paga",
          // },
          // {
          //   value: "contractPendingPayment",
          //   text: "Assinatura aguardando pagamento",
          // },
          {
            value: "contractPaid",
            text: "Assinatura paga",
          },
          {
            value: "contractCanceled",
            text: "Assinatura Cancelada",
          },
          {
            value: "contractEnded",
            text: "Assinatura Finalizada",
          },
          {
            value: "contractTrialing",
            text: "Assinatura em Trial",
          },
        ],
      },
    };
  },
  props: {
    id_product: {
      type: Number,
    },
  },
  components: {
    Multiselect,
    Conexoes,
  },
  methods: {
    triggerChanged(v) {
      const include = this.events.trigger.includes(v);

      if (v == "saleRefused") {
        if (!include) {
          document.querySelector(`#check-refunsed-0`).click();
        } else {
          this.events.options_salesRefused.forEach((_, index) => {
            if (this.selectEventRefunsed[index]) {
              document.querySelector(`#check-refunsed-${index}`).click();
            }
          });
        }
      } else if (v == "saleWaitingPayment") {
        if (!include) {
          document.querySelector(`#check-waiting-0`).click();
        } else {
          this.events.options_salesWaiting.forEach((_, index) => {
            if (this.selectEventWaiting[index]) {
              document.querySelector(`#check-waiting-${index}`).click();
            }
          });
        }
      }

      if (include) {
        this.events.trigger.splice(this.events.trigger.indexOf(v), 1);
      } else {
        this.events.trigger.push(v);
      }
    },
    subTriggerChanged(v, type) {
      if (type === "saleRefused") {
        const include = this.events.sub_trigger.includes(v);

        if (v == "TODOS") {
          if (!include) {
            this.selectEventRefunsed = [];
            this.events.options_salesRefused.forEach((_, index) => {
              if (index > 0) {
                this.init_click = true;
                if (!this.selectEventRefunsed[index]) {
                  document.querySelector(`#check-refunsed-${index}`).click();
                }
              }
            });
          } else {
            this.events.options_salesRefused.forEach((_, index) => {
              if (index > 0) {
                if (this.selectEventRefunsed[index]) {
                  document.querySelector(`#check-refunsed-${index}`).click();
                }
              }
            });
          }
        }

        if (include) {
          this.events.sub_trigger.splice(this.events.sub_trigger.indexOf(v), 1);
        } else {
          this.events.sub_trigger.push(v);
        }
      } else {
        const include = this.events.sub_trigger2.includes(v);

        if (v == "TODOS") {
          if (!include) {
            this.selectEventWaiting = [];
            this.events.options_salesWaiting.forEach((_, index) => {
              if (index > 0) {
                this.init_click = true;
                if (!this.selectEventWaiting[index]) {
                  document.querySelector(`#check-waiting-${index}`).click();
                }
              }
            });
          } else {
            this.events.options_salesWaiting.forEach((_, index) => {
              if (index > 0) {
                if (this.selectEventWaiting[index]) {
                  document.querySelector(`#check-waiting-${index}`).click();
                }
              }
            });
          }
        }

        if (include) {
          this.events.sub_trigger2.splice(
            this.events.sub_trigger2.indexOf(v),
            1
          );
        } else {
          this.events.sub_trigger2.push(v);
        }
      }
    },
    // openDocumentacaoWebHook() {
    //   window.open("https://www.voompcreators.com.br/termos-e-documentacoes/");
    // },
    resetContaAzul() {
      this.contaazul = {
        data: {
          type: "contaazul",
          product_id: null,
          connection_id: null,
          connection_name: null,
        },
        product_id: null,
        connection_id: null,
        options_conexao: [],
        options: [],
      };
      this.events = {
        trigger: [],
        sub_trigger: [],
        sub_trigger2: [],
        callback: "executarContaazul",
        options_sales: [
          {
            value: "saleUnpaid",
            text: "Venda não paga",
          },
          {
            value: "saleWaitingPayment",
            text: "Venda aguardando pagamento",
          },
          {
            value: "salePaid",
            text: "Venda Paga",
          },
          {
            value: "saleRefused",
            text: "Venda Recusada",
          },
          {
            value: "saleRefunded",
            text: "Venda Reembolsada",
          },
          {
            value: "saleChargedback",
            text: "Chargeback realizado",
          },
        ],
        options_salesWaiting: [
          {
            value: "TODOS",
            text: "Todos",
          },
          {
            value: "BOLETO",
            text: "Boleto",
          },
          {
            value: "PIX",
            text: "PIX",
          },
        ],
        options_salesRefused: [
          {
            value: "TODOS",
            text: "Todos",
          },
          {
            value: "INVALID_CREDIT_CARD",
            text: "Cartão inválido/bloqueado",
          },
          {
            value: "INSUFFICIENT_FUNDS",
            text: "Cartão sem limite",
          },
          {
            value: "INVALID_PAYMENT_TYPE",
            text: "Método de pagamento inválido",
          },

          {
            value: "INVALID_DATA",
            text: "Dados do cartão inválidos",
          },
          {
            value: "INVALID_CLIENT_DATA",
            text: "Dados do cliente inválidos",
          },
          {
            value: "INVALID_CVV",
            text: "CVV do cartão inválido",
          },
          {
            value: "BANK",
            text: "Problema: Banco emissor do cartão",
          },
          {
            value: "CREDIT_CARD_OPERATOR",
            text: "Problema: Operadora do cartão",
          },

          {
            value: "INVALID_INSTALLMENTS",
            text: "Qtd parcelas não autorizada",
          },
          {
            value: "CURRENCY_NOT_SUPPORTED",
            text: "Moeda não autorizada",
          },
          {
            value: "SUSPECTED_FRAUD",
            text: "Suspeita de Fraude",
          },
          {
            value: "GENERIC",
            text: "Erro ao processar a compra",
          },
        ],
        options_sub: [
          {
            value: "contractUnpaid",
            text: "Assinatura não paga",
          },
          {
            value: "contractPendingPayment",
            text: "Assinatura aguardando pagamento",
          },
          {
            value: "contractPaid",
            text: "Assinatura paga",
          },
          {
            value: "contractCanceled",
            text: "Assinatura Cancelada",
          },
          {
            value: "contractEnded",
            text: "Assinatura Finalizada",
          },
          {
            value: "contractTrialing",
            text: "Assinatura em Trial",
          },
        ],
      };
    },
    createContaAzul(contaazul) {
      let data = contaazul;
      serviceCallback
        .create(data)
        .then(() => {
          console.log('createContaAzul',contaazul)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    
    updateContaAzul(contaazul) {
      let data = contaazul;
      serviceCallback
        .update(data)
        .then(() => {
          console.log('updateContaAzul',contaazul)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // abrir modal de conexoes
    openConexoes() {
      this.$bvModal.show("modal-conexoes");
    },

    // adicionar um novo item no contaazul
    async adicionarCallbacksContaAzul() {
      if (this.events.trigger.length) {
        this.$validator.validateAll("contaazul").then(async (result) => {
          if (result) {
            this.contaazul.product_id = this.id_product;
            this.contaazul.connection_id = this.contaazul.data.connection_id;
            [...this.events.trigger].forEach((item) => {
              if (item == "saleRefused") {
                if (this.events.sub_trigger.includes("TODOS")) {
                  this.contaazul.object.push({
                    product_id: this.id_product,
                    connection_id: this.contaazul.data.connection_id,
                    type: "contaazul",
                    trigger: item,
                    callback: "executarContaazul",
                  });
                }
              } else if (item == "saleWaitingPayment") {
                if (this.events.sub_trigger2.includes("TODOS")) {
                  this.contaazul.object.push({
                    product_id: this.id_product,
                    connection_id: this.contaazul.data.connection_id,
                    type: "contaazul",
                    trigger: item,
                    callback: "executarContaazul",
                  });
                }
              } else {
                this.contaazul.object.push({
                  product_id: this.id_product,
                  connection_id: this.contaazul.data.connection_id,
                  type: "contaazul",
                  trigger: item,
                  callback: "executarContaazul",
                });
              }
            });

            if (
              this.events.trigger.includes("saleRefused") &&
              this.events.sub_trigger.length &&
              !this.events.sub_trigger.includes("TODOS")
            ) {
              [...this.events.sub_trigger].forEach((item) => {
                if (item != "TODOS") {
                  this.contaazul.object.push({
                    product_id: this.id_product,
                    connection_id: this.contaazul.data.connection_id,
                    type: "contaazul",
                    trigger: "saleRefused",
                    sub_trigger: item,
                    callback: "executarContaazul",
                  });
                }
              });
            }

            if (
              this.events.trigger.includes("saleWaitingPayment") &&
              this.events.sub_trigger2.length &&
              !this.events.sub_trigger2.includes("TODOS")
            ) {
              [...this.events.sub_trigger2].forEach((item) => {
                if (item != "TODOS") {
                  this.contaazul.object.push({
                    product_id: this.id_product,
                    connection_id: this.contaazul.data.connection_id,
                    type: "contaazul",
                    trigger: "saleWaitingPayment",
                    sub_trigger: item,
                    callback: "executarContaazul",
                  });
                }
              });
            }

            await this.contaazul.object.forEach(async (item) => {
              let exists = await this.contaazul.callbacks.filter(x => {
                if(typeof item.sub_trigger !== 'undefined') {
                  if(x.product_id == item.product_id && x.trigger == item.trigger && x.sub_trigger == item.sub_trigger) {
                    return x
                  }
                } else {
                  if(x.product_id == item.product_id && x.trigger == item.trigger) {
                    return x
                  }
                }
              });
              if(exists.length == 0) {
                await this.createContaAzul(item);
              } else {
                item.id = exists[0].id;
                await this.updateContaAzul(item);
              }
              
            });
            await this.authorizeConnection();
          }
        });
      } else {
        this.$bvToast.toast("Todos os campos são obrigatórios. Verifique", {
          title: "Conta Azul",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
      }
    },
    
    // adicionar um novo item no contaazul
    async adicionarContaAzul() {
      this.submit = true;
      this.loading = true;
      this.contaazul.data.product_id = this.id_product;
      await this.$validator.validateAll("contaazul").then((result) => {
        if (result) {
          serviceReleaseRule
            .create(this.contaazul.data)
            .then(async (response) => {
              if (response.success) {
                this.$bvToast.toast("Regra de liberação criada com sucesso", {
                  title: "Conta Azul",
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
                await this.adicionarCallbacksContaAzul();
              } else {
                this.$bvToast.toast(
                  "Houve algum erro ao criar a Regra de liberação",
                  {
                    title: "Conta Azul",
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
              }
              // this.$bvModal.hide("modal-integracoes");
            })
            .catch((error) => {
              console.log(error);
              this.loading = false;
              this.submit = false;
            });
        }
      });
    },

    // limpa campos
    changeConnection() {
      this.loading = true;
      this.contaazul.options = [];
      if(this.contaazul.data.connection_id) {
        this.contaazul.data.connection_name = this.contaazul.options_conexao.filter(x => x.value == this.contaazul.data.connection_id)[0].text;
      }
      console.log("changeConnection",this.contaazul.data)
      this.loading = false;
    },

    // remove conexão selecionada
    async removeConnection(){

      const remove = await Vue.swal({
        title: "Remover conexão",
        text: "Tem certeza que deseja remover esta conexão?",
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      })

      if (remove.isConfirmed) {
        const id = this.contaazul.data.connection_id;
    
        try {
          const result = await serviceConnection.destroy(id);

          if(result.success){
            this.$bvToast.toast("Conexão removida com sucesso!", {
              title: "Conta Azul",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });

            await this.fetchConnections();

          }else{
            this.$bvToast.toast(result.message, {
              title: "Conta Azul",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
          }

        } catch (error) {
          console.error(error);
        }

      }
    },

    async authorizeConnection() {
      serviceContaAzul
        .create(this.contaazul.data,"/authorize")
        .then(async (response) => {
          console.log("authorizeConnection",response)
          
          // limpando campos
          // await this.resetContaAzul();
          // this.selectEvent = await [];
          // this.selectEventRefunsed = await [];
          // this.selectEventWaiting = await []; 
          await window.open(response.redirect,'_self');
        });
    },

    // funcao para puxar as conexoes e atribuir
    fetchConnections() {
      let data = {
        type: "contaazul",
        product_id: null,
        connection_id: null,
        connection_name: null,
      };

      this.contaazul.options_conexao = [];

      serviceConnection
        .search(data)
        .then((response) => {
          console.log("fetchConnections",response);
          [...response].filter((item) => {
            if (item.type == "contaazul") {
              if(response.length == 1) {
                this.contaazul.data.connection_id = item.id;
                this.contaazul.data.connection_name = item.title;
              }
              this.contaazul.options_conexao.push({
                value: item.id,
                text: item.title,
              });
            }
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // puxa todos os callbacks da conta azul
    async getCallbacks() {
      this.loading = true;
      let data = {
        product_id: this.id_product,
        type: "contaazul",
      };
      serviceCallback
        .search(data)
        .then(async (r) => {
          this.contaazul.callbacks = await r;
          await console.log("getCallbacks",this.contaazul.callbacks);
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        })
        .finally(() => {
          setTimeout(() => {
            this.renewCallbacks();
          }, 500);
        })
    },
    async renewCallbacks() {
      if(await this.contaazul.callbacks.length) {            
        this.contaazul.has_connection = true;
        
        await this.contaazul.callbacks.forEach(async (item) => {
          await this.triggerChanged(item.trigger);

          [...this.events.options_sales].forEach((x,k) => {
            if(x['value'] == item.trigger) {
              this.selectEvent[k] = true;
            }
          });

        });
        
        this.contaazul.data.connection_id = await this.contaazul.callbacks[0].connection_id;
        console.log('connection_id',this.contaazul.callbacks[0].connection_id)
        let filter_con = await this.contaazul.options_conexao.filter(x => x.value == this.contaazul.callbacks[0].connection_id);
        console.log('filter_con 2',filter_con)
        this.contaazul.data.connection_name = await Array.isArray(filter_con) == true ? filter_con[0]['text'] : filter_con['text'];
      } else {
        this.contaazul.has_connection = false;
      }
    },
    // abrindo modal e setando valores
    async openModal() {
      this.loading = true;

      this.contaazul.data = await {
        type: "contaazul",
        product_id: null,
        connection_id: null,
        connection_name: null,
      };
      // puxando as conexões do contaazul
      await this.fetchConnections();
      await this.getCallbacks();
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}

.title-inter {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #2a63ab;
}

.form-group + .form-group {
  margin-top: 15px;
}

#toolbar-conexao {
  margin: 0 0 30px 0;
}

#toolbar-conexao button {
  font-size: 12px;
  margin: 0 10px;
}

</style>
