<template>
  <BaseModal
    name="modal-webhook"
    size="lg"
    title="Adicione a entrega via Webhook"
  >
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <section class="container-arquivos" v-else>
      <div class="lista-entrega lista-arquivos mt-4">
        <div>
          <h4 class="title-inter">• Webhook</h4>
          <div class="d-flex justify-content-end">
            <div>
              <BaseButton
                variant="secondary_outline"
                class="link-documentacao"
                @click="openDocumentacaoWebHook"
                >Documentação</BaseButton
              >
            </div>
          </div>
          <b-form novalidate>
            <h4 class="title-inter">Quando acontecer o(s) evento(s)</h4>
            <div class="grid-events">
              <b-form-group class="sales-area" label="Vendas" label-for="check">
                <b-form-checkbox
                  size="md"
                  switch
                  class="check-box mb-2"
                  v-for="(op, index) in events.options_sales"
                  :name="`check-${index}`"
                  :id="`check-${index}`"
                  v-model="selectEvent[index]"
                  :key="op.value"
                  @change="triggerChanged(op.value)"
                >
                  <p class="info-checkbox">{{ op.text }}</p>
                  <div
                    v-show="op.value == 'saleRefused' && selectEvent[1]"
                    data-anima="top"
                    class="mt-3 mb-3"
                  >
                    <b-form-checkbox
                      size="md"
                      switch
                      v-for="(sub, index) in events.options_salesRefused"
                      class="check-box mb-2"
                      :name="`check-refunsed-${index}`"
                      :id="`check-refunsed-${index}`"
                      :key="sub.value"
                      v-model="selectEventRefunsed[index]"
                      @change="subTriggerSaleRefusedChanged(sub.value, 'saleRefused')"
                    >
                      <p class="info-checkbox">{{ sub.text }}</p>
                    </b-form-checkbox>
                  </div>
                  <div
                    v-show="op.value == 'saleWaitingPayment' && selectEvent[5]"
                    data-anima="top"
                    class="mt-3 mb-3"
                  >
                    <b-form-checkbox
                      size="md"
                      switch
                      v-for="(sub, index) in events.options_salesWaiting"
                      class="check-box mb-2"
                      :name="`check-waiting-${index}`"
                      :id="`check-waiting-${index}`"
                      :key="sub.value"
                      v-model="selectEventWaiting[index]"
                      @change="
                        subTriggerSaleWaitingPaymentChanged(sub.value, 'saleWaitingPayment')
                      "
                    >
                      <p class="info-checkbox">{{ sub.text }}</p>
                    </b-form-checkbox>
                  </div>
                </b-form-checkbox>
              </b-form-group>

              <b-form-group class="signature-area" label="Assinatura" label-for="check">
                <b-form-checkbox
                  size="md"
                  switch
                  class="check-box mb-2"
                  name="check"
                  v-for="op in events.options_sub"
                  :key="op.value"
                  @change="triggerChanged(op.value)"
                >
                  <p class="info-checkbox">{{ op.text }}</p>
                </b-form-checkbox>
              </b-form-group>

              <b-form-group class="sales-recover-area" label="Recuperação de vendas" label-for="sales-recovery">
                <b-form-checkbox
                  size="md"
                  switch
                  class="check-box mb-2"
                  v-for="(op, index) in events.optionsSalesRecovery"
                  :name="`sales-recovery-${index}`"
                  :id="`sales-recovery-${index}`"
                  :key="op.value"
                  v-model="selectEventSalesRecovery[index]"
                  @change="triggerChanged(op.value)"
                >
                  <p class="info-checkbox">{{ op.text }}</p>
                  <section class="sale-recovery-section">
                    <div
                    v-show="op.value == 'saleDueDays' && selectEventSalesRecovery[1]"
                    data-anima="top"
                    class="mt-3 mb-3 sale-recovery-section-item"
                    >
                    <p class="info-checkbox">Dias antes do vencimento</p>
                      <b-form-checkbox
                        size="md"
                        switch
                        v-for="(sub, index) in events.options_saleDueDaysBefore"
                        class="check-box mb-2"
                        :name="`sales-recovery-days-before-${index}`"
                        :id="`sales-recovery-days-before-${index}`"
                        :key="sub.value"
                        v-model="selectDueDaysBefore[index]"
                        @change="subTriggerSalesDueDays(sub.value, 'saleDueDays')"
                      >
                        <p class="info-checkbox">{{ sub.text }}</p>
                      </b-form-checkbox>
                    </div>
                    <div
                    v-show="op.value == 'saleDueDays' && selectEventSalesRecovery[1]"
                    data-anima="top"
                    class="mt-3 mb-3 sale-recovery-section-item"
                    >

                      <p class="info-checkbox">Dias após o vencimento</p>
                      <b-form-checkbox
                        size="md"
                        switch
                        v-for="(sub, index) in events.options_saleDueDaysAfter"
                        class="check-box mb-2"
                        :name="`sales-recovery-days-after-${index}`"
                        :id="`sales-recovery-days-after-${index}`"
                        :key="sub.value"
                        v-model="selectDueDaysAfter[index]"
                        @change="subTriggerSalesDueDays(sub.value, 'saleDueDays')"
                      >
                        <p class="info-checkbox">{{ sub.text }}</p>
                      </b-form-checkbox>
                    </div>
                  </section>
                </b-form-checkbox>
              </b-form-group>
            </div>

            <b-form-group label="Enviar para a URL" label-for="url_webhook">
              <b-form-input
                v-validate="{ url: { require_protocol: true } }"
                name="url_webhook"
                id="url_webhook"
                placeholder="https://plataforma.com/api"
                type="text"
                v-model="events.url"
                autocomplete="off"
              />
              <b-form-invalid-feedback :state="!errors.has('url_webhook')">
                Entre com uma URL válida
              </b-form-invalid-feedback>
            </b-form-group>
          </b-form>
        </div>
      </div>
    </section>

    <template v-slot:footer="{ cancel }">
      <BaseButton
        :disabled="loading"
        variant="link-info"
        class="mr-4"
        @click="cancel"
      >
        Cancelar
      </BaseButton>
      <BaseButton variant="info3" :disabled="loading" @click="adicionarWebhook">
        Criar Liberação
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
// services:
import CallbackService from "@/services/resources/CallbackService";
const serviceCallback = CallbackService.build();

export default {
  data() {
    return {
      loading: false,
      submit: false,
      webhook: {
        user_id: "",
        product_id: "",
        type: "webhook",
        object: [],
      },
      selectEvent: [],
      selectEventSalesRecovery: [],
      selectEventRefunsed: [],
      selectEventWaiting: [],
      selectDueDaysBefore: [],
      selectDueDaysAfter: [],
      init_click: false,
      events: {
        trigger: [],
        subTriggerSaleRefused: [],
        subTriggerSaleWaitingPayment: [],
        subTriggerSalesDueDays: [],
        url: "",
        callback: "executarWebhook",
        options_sales: [
          {
            value: "salePaid",
            text: "Venda paga",
          },
          {
            value: "saleRefused",
            text: "Venda recusada",
          },
          {
            value: "saleRefunded",
            text: "Venda reembolsada",
          },
          {
            value: "saleChargedback",
            text: "Chargeback realizado",
          },
          {
            value: "saleUnpaid",
            text: "Venda não paga",
          },
          {
            value: "saleWaitingPayment",
            text: "Venda aguardando pagamento",
          },
        ],
        options_salesWaiting: [
          {
            value: "TODOS",
            text: "Todos",
          },
          {
            value: "BOLETO",
            text: "Boleto",
          },
          {
            value: "PIX",
            text: "PIX",
          },
        ],
        options_salesRefused: [
          {
            value: "TODOS",
            text: "Todos",
          },
          {
            value: "INVALID_CREDIT_CARD",
            text: "Cartão inválido/bloqueado",
          },
          {
            value: "INSUFFICIENT_FUNDS",
            text: "Cartão sem limite",
          },
          {
            value: "INVALID_PAYMENT_TYPE",
            text: "Método de pagamento inválido",
          },

          {
            value: "INVALID_DATA",
            text: "Dados do cartão inválidos",
          },
          {
            value: "INVALID_CLIENT_DATA",
            text: "Dados do cliente inválidos",
          },
          {
            value: "INVALID_CVV",
            text: "CVV do cartão inválido",
          },
          {
            value: "BANK",
            text: "Problema: Banco emissor do cartão",
          },
          {
            value: "CREDIT_CARD_OPERATOR",
            text: "Problema: Operadora do cartão",
          },

          {
            value: "INVALID_INSTALLMENTS",
            text: "Qtd parcelas não autorizada",
          },
          {
            value: "CURRENCY_NOT_SUPPORTED",
            text: "Moeda não autorizada",
          },
          {
            value: "SUSPECTED_FRAUD",
            text: "Suspeita de fraude",
          },
          {
            value: "GENERIC",
            text: "Erro ao processar a compra",
          },
        ],
        options_sub: [
          {
            value: "contractUnpaid",
            text: "Assinatura não paga",
          },
          {
            value: "contractPaid",
            text: "Assinatura ativa",
          },
          {
            value: "contractTrialing",
            text: "Assinatura em trial",
          },
          {
            value: "contractPendingPayment",
            text: "Assinatura aguardando pagamento",
          },
          {
            value: "contractEnded",
            text: "Assinatura encerrada",
          },
          {
            value: "contractCanceled",
            text: "Assinatura cancelada",
          },
        ],
        optionsSalesRecovery: [
          {
            value: "checkoutAbandoned",
            text: "Checkout abandonado",
          },
          {
            value: "saleDueDays",
            text: "Aviso de vencimento de boleto para assinatura",
          }
        ],
        options_saleDueDaysBefore: [
          {
            value: 7,
            text: "7 dias",
          },
          {
            value: 5,
            text: "5 dias",
          },
          {
            value: 3,
            text: "3 dias",
          },
          {
            value: 2,
            text: "2 dia",
          },
          {
            value: 1,
            text: "1 dia",
          },
          {
            value: 0,
            text: "Dia do vencimento",
          }
        ],
        options_saleDueDaysAfter: [
          {
            value: -1,
            text: "1 dia",
          },
          {
            value: -2,
            text: "2 dia",
          },
          {
            value: -3,
            text: "3 dias",
          },
          {
            value: -5,
            text: "5 dias",
          },
          {
            value: -7,
            text: "7 dias",
          },
          {
            value: -30,
            text: "30 Dias",
          }
        ]
      },
    };
  },
  props: {
    id_product: {
      type: Number,
    },
  },

  methods: {
    triggerChanged(v) {
      const include = this.events.trigger.includes(v);

      if (v == "saleRefused") {
        this.triggerSaleRefused(include);
      }
      if (v == "saleWaitingPayment") {
        this.triggerSaleWaitingPayment(include);
      }
      if (v == "saleDueDays") {
        this.triggerSalesDueDays(include);
      }

      if (include) {
        this.events.trigger.splice(this.events.trigger.indexOf(v), 1);
      } else {
        this.events.trigger.push(v);
      }
    },
    triggerSaleRefused(include) {
      if (!include) {
          document.querySelector(`#check-refunsed-0`).click();
        } else {
          this.events.options_salesRefused.forEach((_, index) => {
            if (this.selectEventRefunsed[index]) {
              document.querySelector(`#check-refunsed-${index}`).click();
            }
          });
        }
    },
    triggerSaleWaitingPayment(include) {
      if (!include) {
        document.querySelector(`#check-waiting-0`).click();
      } else {
        this.events.options_salesWaiting.forEach((_, index) => {
          if (this.selectEventWaiting[index]) {
            document.querySelector(`#check-waiting-${index}`).click();
          }
        });
      }
    },
    triggerSalesDueDays(include) {
      const hasDueDays7DaysBefore = this.events.subTriggerSalesDueDays.includes(7)
      if (!include && !hasDueDays7DaysBefore) {
        document.querySelector(`#sales-recovery-days-before-0`).click();
      }
    },
    subTriggerSaleRefusedChanged(v, type) {
      const include = this.events.subTriggerSaleRefused.includes(v);

      if (v == "TODOS") {
        if (!include) {
          this.selectEventRefunsed = [];
          this.events.options_salesRefused.forEach((_, index) => {
            if (index > 0) {
              this.init_click = true;
              if (!this.selectEventRefunsed[index]) {
                document.querySelector(`#check-refunsed-${index}`).click();
              }
            }
          });
        } else {
          this.events.options_salesRefused.forEach((_, index) => {
            if (index > 0) {
              if (this.selectEventRefunsed[index]) {
                document.querySelector(`#check-refunsed-${index}`).click();
              }
            }
          });
        }
      }

      if (include) {
        this.events.subTriggerSaleRefused.splice(this.events.subTriggerSaleRefused.indexOf(v), 1);
      } else {
        this.events.subTriggerSaleRefused.push(v);
      }
    },
    subTriggerSaleWaitingPaymentChanged(v, type) {
      const include = this.events.subTriggerSaleWaitingPayment.includes(v);

      if (v == "TODOS") {
        if (!include) {
          this.selectEventWaiting = [];
          this.events.options_salesWaiting.forEach((_, index) => {
            if (index > 0) {
              this.init_click = true;
              if (!this.selectEventWaiting[index]) {
                document.querySelector(`#check-waiting-${index}`).click();
              }
            }
          });
        } else {
          this.events.options_salesWaiting.forEach((_, index) => {
            if (index > 0) {
              if (this.selectEventWaiting[index]) {
                document.querySelector(`#check-waiting-${index}`).click();
              }
            }
          });
        }
      }

      if (include) {
        this.events.subTriggerSaleWaitingPayment.splice(
          this.events.subTriggerSaleWaitingPayment.indexOf(v),
          1
        );
      } else {
        this.events.subTriggerSaleWaitingPayment.push(v);
      }
    },
    subTriggerSalesDueDays(value, type) {
      const include = this.events.subTriggerSalesDueDays.includes(value);

      if (include) {
        this.events.subTriggerSalesDueDays.splice(this.events.subTriggerSalesDueDays.indexOf(value), 1);
      } else {
        this.events.subTriggerSalesDueDays.push(value);
      }
    },
    openDocumentacaoWebHook() {
      window.open("https://www.voompcreators.com.br/termos-e-documentacoes/");
    },
    resetWebHook() {
      this.webhook = {
        user_id: "",
        product_id: "",
        type: "webhook",
        object: [],
      };
      
      this.events.trigger = []
      this.events.subTriggerSaleRefused = []
      this.events.subTriggerSaleWaitingPayment = []
      this.events.subTriggerSalesDueDays = []
      this.events.url = ""
      this.selectEvent = [];
      this.selectEventRefunsed = [];
      this.selectEventWaiting = [];
      this.selectEventSalesRecovery = [];
      this.selectDueDaysBefore = [];
      this.selectDueDaysAfter = [];
    },
    createWebhook(webhook) {
      let data = webhook;
      serviceCallback
        .create(data)
        .then(() => {
          this.submit = false;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async adicionarWebhook() {
      this.submit = true;

      const hasSaleDueDaysSubTrigger = this.events.trigger.includes('saleDueDays') && !this.events.subTriggerSalesDueDays.length
      if (!this.events.trigger.length || !this.events.url?.length || hasSaleDueDaysSubTrigger) {
        return this.$bvToast.toast("Todos os campos são obrigatórios. Verifique", {
          title: "Webhook",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
      }

      const result = await this.$validator.validate("url_webhook")
      if (!result) {
        if (document.querySelector("#url_webhook")) {
          document.querySelector("#url_webhook").classList.add("erro");
          document.querySelector("#url_webhook").focus();
        }
        this.$bvToast.toast("Entre com uma URL válida", {
          title: "Webhook",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
      }

      this.loading = true;
      document.querySelector("#url_webhook").classList.remove("erro");
      this.webhook.product_id = this.id_product;
      [...this.events.trigger].forEach((item) => {
        if (item == "saleRefused") {
          if (this.events.subTriggerSaleRefused.includes("TODOS")) {
            this.webhook.object.push({
              product_id: this.id_product,
              type: "webhook",
              trigger: item,
              callback: "executarWebhook",
              url: this.events.url,
            });
          }
        } else if (item == "saleWaitingPayment") {
          if (this.events.subTriggerSaleWaitingPayment.includes("TODOS")) {
            this.webhook.object.push({
              product_id: this.id_product,
              type: "webhook",
              trigger: item,
              callback: "executarWebhook",
              url: this.events.url,
            });
          }
        } else if (item !== "saleDueDays") {
          this.webhook.object.push({
            product_id: this.id_product,
            type: "webhook",
            trigger: item,
            callback: "executarWebhook",
            url: this.events.url,
          });
        }
      });

      if (
        this.events.trigger.includes("saleRefused") &&
        this.events.subTriggerSaleRefused.length &&
        !this.events.subTriggerSaleRefused.includes("TODOS")
      ) {
        [...this.events.subTriggerSaleRefused].forEach((item) => {
          if (item != "TODOS") {
            this.webhook.object.push({
              product_id: this.id_product,
              type: "webhook",
              trigger: "saleRefused",
              sub_trigger: item,
              callback: "executarWebhook",
              url: this.events.url,
            });
          }
        });
      }

      if (
        this.events.trigger.includes("saleWaitingPayment") &&
        this.events.subTriggerSaleWaitingPayment.length &&
        !this.events.subTriggerSaleWaitingPayment.includes("TODOS")
      ) {
        [...this.events.subTriggerSaleWaitingPayment].forEach((item) => {
          if (item != "TODOS") {
            this.webhook.object.push({
              product_id: this.id_product,
              type: "webhook",
              trigger: "saleWaitingPayment",
              sub_trigger: item,
              callback: "executarWebhook",
              url: this.events.url,
            });
          }
        });
      }

      const hasDueDaysTrigger = this.events.trigger.includes('saleDueDays')
      if (hasDueDaysTrigger && this.events.subTriggerSalesDueDays.length) {
        const triggersDueDays = this.events.subTriggerSalesDueDays.map((item) => ({
            product_id: this.id_product,
            type: "webhook",
            trigger: "saleDueDays",
            sub_trigger: item,
            callback: "executarWebhook",
            url: this.events.url
        }))
        this.webhook.object = [...this.webhook.object, ...triggersDueDays]
      }

      this.webhook.object.forEach((item) => {
        this.createWebhook(item);
      });
      this.$bvToast.toast("Criando Webhooks", {
        title: "Webhook",
        variant: "success",
        autoHideDelay: 5000,
        appendToast: true,
      });

      // fechando modal
      this.$bvModal.hide("modal-webhook");
      // limpando campos
      this.resetWebHook();
      //emitindo evento para o componente pai (opcoesEntrega)
      setTimeout(() => {
        this.$emit("close");
      }, 1000);
      this.loading = false;
      this.submit = false;
    },
  },
  filters: {
    captalize(value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  }
};
</script>

<style scoped>
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
.title-inter {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #2a63ab;
}
.form-group + .form-group {
  margin-top: 15px;
}
.check-box {
  /* margin-left: 40px; */
}
.check-box .info-checkbox {
  font-size: 13px;
  font-weight: 500;
  margin-left: 5px;
  cursor: pointer;
  max-width: 250px;
}
.sale-recovery-section .info-checkbox {
  margin-bottom: 1em;
}
.grid-events {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  margin: 20px 0;
  grid-template-areas:
    "header sidebar"
    "main   main";
}
.sales-recover-area {
  grid-area: main;
}
.sales-area {
  grid-area: header;
}
.signature-area {
  grid-area: sidebar;
}
.sale-recovery-section {
  display: flex;
}
.sale-recovery-section-item {
  flex: 1
}
.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
</style>
