<template>
  <BaseModal
    name="modal-conexoes"
    size="lg"
    :title="$t('seller.my_account.conexoes.text_1845')"
  >
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div v-else>
      <h4 class="title-inter">• {{ $t("seller.my_account.conexoes.text_300") }}</h4>

      <form>
        <b-form-group :label="$t('seller.my_account.conexoes.text_1850')">
          <b-form-select
            v-model="plataform"
            :options="plataforms"
            v-validate="'required'"
            name="plataform"
            :disabled="default_plataform === true"
          ></b-form-select>
          <b-form-invalid-feedback :state="submit ? !errors.has('plataform') : null">
            {{ $t("seller.my_account.conexoes.text_301") }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-row>
          <b-col cols="12" :md="plataform === 'contaazul' ? '12' : '6'">
            <b-form-group
              :label="$t('seller.my_account.conexoes.text_1849')"
              label-for="connection_name"
            >
              <b-form-input
                id="connection_name"
                name="connection_name"
                v-model="connection_name"
                v-validate="'required'"
                type="text"
              ></b-form-input>
              <b-form-invalid-feedback
                :state="submit ? !errors.has('connection_name') : null"
              >
                {{ $t("seller.my_account.conexoes.text_302") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" v-if="plataform !== 'contaazul'">
            <b-form-group
              :label="$t('seller.my_account.conexoes.text_1848')"
              label-for="api_key"
            >
              <b-form-input
                id="api_key"
                name="api_key"
                v-model="connection_api_key"
                v-validate="'required'"
                type="text"
              ></b-form-input>
              <b-form-invalid-feedback :state="submit ? !errors.has('api_key') : null">
                {{ $t("seller.my_account.conexoes.text_1846") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>

      </form>
    </div>

    <template v-slot:footer="{ cancel }">
      <BaseButton :disabled="loading" variant="link-info" class="mr-4" @click="cancel">
        {{ $t("seller.my_account.conexoes.text_1851") }}
      </BaseButton>

      <BaseButton variant="secondary" :disabled="loading" @click="createIntegration">{{
        $t("seller.my_account.conexoes.text_1852")
      }}</BaseButton>
    </template>
  </BaseModal>
</template>

<script>
// services:
import ConnectionService from "@/services/resources/ConnectionService";
const serviceConnection = ConnectionService.build();

export default {
  data() {
    return {
      loading: false,
      submit: false,
      plataform: null,
      default_plataform: false,
      connection_name: null,
      connection_api_key: null,
      scope: 'sales',
      plataforms: [
        { value: null, text: this.$t("seller.my_account.conexoes.text_1853") },
        { value: "memberkit", text: "Memberkit" },
        { value: "contaazul", text: "Conta Azul" },
      ],
      api_key: "",
      modal_data:null,
    };
  },
  methods: {
    createIntegration() {
      this.submit = true;

      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = true;
          let data = {};
          if(this.plataform == 'memberkit') {
            data = {
              title: this.connection_name,
              type: this.plataform,
              api_key: this.connection_api_key,
            };
          } else if(this.plataform == 'contaazul') {
            data = {
              title: this.connection_name,
              type: this.plataform,
              scope: this.scope,
            };
          }

          serviceConnection
            .create(data)
            .then((response) => {
              this.$bvToast.toast(this.$t("seller.my_account.conexoes.text_303"), {
                title: this.$t("seller.my_account.conexoes.text_1854"),
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.connection_name = null;
              this.connection_api_key = null;
              this.plataform = null;
              this.default_plataform = false;
              this.modal_data = null;
              this.$bvModal.hide("modal-conexoes");
              this.$emit("close");
            })
            .catch((err) => {
              console.log(err);
              this.$bvToast.toast(this.$t("seller.my_account.conexoes.text_304"), {
                title: this.$t("seller.my_account.conexoes.text_1854"),
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .finally(() => {
              this.submit = false;
              this.loading = false;
            });
        }
      });
    },
  },

  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if(modalId == 'modal-conexoes') {        
        if(typeof this.$parent.$parent.$parent.$parent[this.$parent.$parent.$parent.$parent.$vnode.componentOptions.tag] !== 'undefined') {
          this.modal_data = this.$parent.$parent.$parent.$parent[this.$parent.$parent.$parent.$parent.$vnode.componentOptions.tag].data
          this.plataform = this.modal_data.type;
          this.default_plataform = true;
        }
      }
    });
  }

};
</script>

<style scoped>
.title-inter {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #2a63ab;
}
</style>
